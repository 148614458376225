import React from 'react'
import {useNavigate} from 'react-router-dom';
import logo from '../assets/images/Heil_bucket_logo.svg'
export default function UsePolicy() {
    const navigate = useNavigate();
  return (
    <div>
        <div className='about-us-hero'>
            <div className='container-fluid my-desktop-menu'>
                <div className='row navbar-sec'>
                    <div className='col-xxl-8 col-xl-7 col-lg-7'><a href='/' ><img alt='file share website, send large files' className='logo-img' src={logo} /></a></div>
                    <div className='col-xxl-4 col-xl-5 col-lg-5'>
                        <ul className="nav justify-content-end nav-sec-nav">
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" aria-current="page" onClick={()=>navigate("/")} href="#">Home</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" aria-current="page" onClick={()=>navigate("/upload/")} href="#">Upload</button>
                            </li>
                            
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" onClick={()=>navigate("/about")} href="#">About Us</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c"  onClick={()=>navigate("/register")}>Sign Up</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" onClick={()=>navigate("/login")} >Sign In</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='container-fluid my-mobile-menu'>
                <div className='row navbar-sec'>
                    <div className='col-lg-9 col-6 col-sm col-md '><a href='#' onClick={()=>navigate("/")}><img className='logo-img mobile-logo' src={logo} /></a></div>
                    <div className='col-lg-3 col-6 col-sm col-md '>
                        <div className='text-end'>
                        <button className="btn-invisible-bg my-mobile-menu " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <i className="bi bi-list text-white bar-ico-menu"></i>
                        </button>
                        </div>
                    
                    </div>
                </div>
            </div>
            
            <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel"><a href='/' ><img alt='file share, send large files free throught helibucket'  className='logo-img-mob-menu' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1669208333/logo_1_ny5kuf_1_hcguue.png' /></a></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <ul className="dropdown-menu-v">
                            <li><a href='/' className="dropdown-item" onClick={()=>navigate("/")} >Home</a></li>
                            <li><a href='/upload/' className="dropdown-item mt-3" onClick={()=>navigate("/upload/")} >Upload</a></li>
                            <li><a href='/about/' className="dropdown-item mt-3" onClick={()=>navigate("/about/")} >About</a></li>
                            <li><a href='/contact/' className="dropdown-item mt-3 " onClick={()=>navigate("/contact/")} >Contact Us</a></li>
                            <li><a href='/register' className="dropdown-item mt-3 "  >Sign Up</a></li>
                            <li><a href='/login' className="dropdown-item mt-3 "  >Sign In</a></li>
                        </ul>
                    </div>
                    <div className='mt-5 text-center'>
                        <button className='rounded-pill btn-mob-menu'>Download Desktop Version</button>
                    </div>
                    <div className='mt-5 text-center'>
                        <div className='mob-menu-social text-center'>
                            <div className='me-3'>
                            <a className='me-3 color-aaa' href='https://www.facebook.com/HeliBucket'><i className="bi bi-facebook fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://instagram.com/heli_bucket'><i className="bi bi-instagram fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://www.linkedin.com/showcase/helibucket/'><i className="bi bi-linkedin fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://twitter.com/heli_bucket'><i className="bi bi-twitter fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://youtube.com/channel/UCykpS0MUzwhdcmRmWpY2uLQ'><i className="bi bi-youtube fs-3"></i></a>
                            </div>
                        
                        </div>
                    </div>
                    
                    
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <p className='hero-heading-about mt-5 mb-5'>Acceptable Use Policy</p>
                </div>
            </div>
        </div>
        <div class="container">
        <div class="row">
            <div class="col">
                <h3 class="text-center" ></h3>
            </div>
        </div>
        <h6>Last Updated: 1st April 2022</h6>
        <p>If you are using any <b>SGR Labs/Heliware/HeliBucket</b> Software & Service, this AUP applies to you. Every client of ours agrees to abide by this AUP and is responsible for any violations. You are not allowed to assist or engage others in a way that would violate this AUP. We will enforce and ensure compliance with this AUP by using methods we consider to be appropriate, such as a complaint, monitoring your usage of our service, monitoring email failures,etc.
        </p>
        <p class="text-start">
        We periodically update these terms and we will let you know when we do through a notification within the SGR
        Labs/Heliware/HeliBucket Platform used to access your subscription (if you have one), or by posting a
        revised copy on our website. You agree to review the AUP on a regular basis and always remain in compliance.
        </p>

        <h5>1.1 REPORTING SUSPECTED VIOLATIONS</h5>
        <ul>
            <li>We encourage recipients of email messages sent using the SGR Labs/Heliware/HeliBucket Service to report
                suspected violations of this AUP to us by forwarding a copy of the received email with FULL headers to
                compliance@heliware.co.in.</li>
            <li>We encourage recipients affected by SMS messages sent by the SGR Labs/Heliware/HeliBucket Service or its
                Affiliate/Client to report suspected violations of this AUP to us by sending a screenshot of the SMS
                received message to compliance@heliware.co.in.</li>
            <li>All other violations including infringement etc. can also be reported to compliance@heliware.co.in.</li>
        </ul>
        <p>We have a policy to investigate all of these reports and to respond in the way we consider appropriate.</p>

        <h5>1.2 NO SPAM PERMITTED
        </h5>
        <p>You will not use the SGR Labs/Heliware/HeliBucket Software & Service in any way (directly or indirectly) to
            send, transmit, handle, distribute or deliver:

        </p>
        <ol>
            <li>unsolicited email (“spam” or “spamming”) in violation of the law;</li>
            <li>email to an address obtained via Internet harvesting methods or any surreptitious methods (e.g.,
                scraping or harvesting);</li>
            <li>email to purchased, rented, or borrowed lists;</li>
            <li>email to an address that is incomplete, inaccurate, and/or not updated for all applicable opt-out
                notifications, using best efforts and best practices in the industry;</li>
            <li>email to lists that are likely to result in an excessive number of unsubscribe requests or SPAM
                complaints or notices, as determined by acceptable industry practices.
            </li>
        </ol>

        <div>
            <h5>1.3 PROHIBITED CONTENT</h5>
            <p>Images/Videos/Data sent or caused to be sent or uploaded or captured, by SGR Labs/Heliware/HeliBucket
                Software cannot:</p>
            <ol>
                <li>employ any technique to otherwise misrepresent, hide or obscure any information;</li>
                <li>use other means of deceptive addressing;</li>
                <li>use a third party's internet domain name without their consent, or be relayed from or through a
                    third party's equipment without the third party's permission;</li>
                <li>contain false or misleading information or otherwise contain false or misleading content.</li>
            </ol>
        </div>
        <h5>1.4 TELEPHONE & SMS MARKETING</h5>
        <p>You must comply with all local, national and relevant international laws relating to Privacy &
            Confidentiality. You must comply with all laws related to the recording of images, videos, etc, and ensure
            all proper consent to record is obtained prior to making any such recording. If you use the SGR
            Labs/Heliware/HeliBucket Service to capture Geospatial Images, Videos, or usage of such data through our
            software you must also comply with all applicable industry standards, including those applicable guidelines
            published by the relevant law/guideline-making bodies.</p>

        <h5>1.5 NO DISRUPTION</h5>
        <p>You agree not to use the SGR Labs/Heliware/HeliBucket Service in a way that impacts the normal operation,
            privacy, integrity, or security of another's property. Another's property includes another's account(s),
            domain name(s), URL(s), website(s), network(s), system(s), facilities, equipment, data, other information,
            or business operations. You also agree not to use the SGR Labs/Heliware/HeliBucket Service to gain
            unauthorized access to, use, monitor, or make an unauthorized reference to, another's property unless you
            have the appropriate express prior consent to do so. Examples of prohibited actions include (without
            limitation): hacking, spoofing, denial of service, mailbombing and/or sending any image/video/email that
            contains or transmits any virus or propagating worm(s), or any malware, whether spyware, adware or other
            such file or program. These restrictions apply regardless of your intent and whether or not you acted
            intentionally or unintentionally.</p>

        <h5>1.6 PROPER USE OF SGR Labs/Heliware/HeliBucket SERVICE</h5>
        <p>You will respect the limits that apply to your usage of the SGR Labs/Heliware/HeliBucket Service as specified
            in the NDA & MoU shared with customers.</p>

        <p>In addition, and without limiting the other requirements in this AUP, you may not (directly or indirectly)
            use the SGR Labs/Heliware/HeliBucket Service with content, or in a manner that:</p>

        <ul>
            <li>is threatening, abusive, harassing, stalking, or defamatory;</li>
            <li>is deceptive, false, misleading, or fraudulent;</li>
            <li>is invasive of another's privacy or otherwise violates another's legal rights (such as rights of privacy
                and publicity);</li>
            <li>contains vulgar, obscene, indecent, or unlawful material;</li>
            <li>infringes a third party's intellectual property right(s);</li>
            <li>publishes, posts, uploads, or otherwise distributes any software, music, videos, images, or other
                material protected by intellectual property laws (or by rights of privacy or publicity) unless you have
                all necessary rights and consents to do so;</li>
            <li>uploads files that contain viruses, corrupted files, or any other similar software or programs that may
                damage the operation of another person's computer;</li>
            <li>downloads any file that you know, or reasonably should know, cannot be legally distributed in that way;
            </li>
            <li>falsifies or deletes any author attributions, legal or proprietary designations, labels of the origin or
                source of software, or other material contained in a file that is uploaded;</li>
            <li>restricts or inhibits any other user of the SGR Labs/Heliware/HeliBucket Service from using and enjoying
                our website and/or the SGR Labs/Heliware/HeliBucket Service/Software;</li>
            <li>harvests or otherwise collects information about others, including e-mail addresses or any such
                information, without their consent;</li>
            <li>violates the usage standards or rules of an entity affected by your use, including without limitation
                any ISP, ESP, or news or user group (andincluding by way of example and not limitation circumventing or
                exceeding equipment use rights and restrictions and/or location and path identification detail). </li>
        </ul>
        <p>You will use the SGR Labs/Heliware/HeliBucket Service for your internal business purposes and will not: (i)
            willfully tamper with the security of the SGR Labs/Heliware/HeliBucket Service/Product/Software or tamper
            with our customer accounts; (ii) access data on the SGR Labs/Heliware/HeliBucket Service/Software not
            intended for you; (iii) log into a server or account on the SGR Labs/Heliware/HeliBucket Service/Software
            that you are not authorized to access; (iv) attempt to probe, scan or test the vulnerability of any SGR
            Labs/Heliware/HeliBucket Software/Service or to breach the security or authentication measures without
            proper authorization; (v) wilfully Software/ Service unusable; (vi) lease, distribute, license, sell or
            otherwise commercially exploit the SGR Labs/Heliware/HeliBucket Service or make the SGR
            Labs/Heliware/HeliBucket Service available to a third party other than as contemplated in your subscription
            to the SGR Labs/Heliware/HeliBucket Service; (vii) use the SGR Labs/Heliware/HeliBucket Service for
            timesharing or service bureau purposes or otherwise for the benefit of a third party; or (viii) provide to
            third parties any evaluation version of the SGR Labs/Heliware/HeliBucket Software/Service without our prior
            written consent.</p>

        <h5>1.7 COMPLIANCE WITH APPLICABLE LAWS</h5>
        <p>In addition, and without limiting the other requirements in this AUP, you may not (directly or indirectly)
            use the SGR Labs/Heliware/HeliBucket Software/Data & export laws and regulations and without limitation:</p>
        <ul>
            <li>Controlling the Assault of Non-Solicited Pornography and Marketing Act (CAN-SPAM Act) (15 U.S.C. § 7701
                et seq.),</li>
            <li>Telephone Consumer Protection Act of 1991 (47 U.S.C. § 227),</li>
            <li>Directive 2000/31/EC of the European Parliament and Council of 8 June 2000, on certain legal aspects of
                information society services, in particular, electronic commerce in the Internal Market (Directive on
                Electronic Commerce), along with the Directive 2002/58/EC of the European Parliament and Council of 12
                July 2002, concerning the processing of personal data and the protection of privacy in the electronic
                communications sector (Directive on Privacy and Electronic Communications),</li>
            <li>Personal Information Protection and Electronic Documents Act (PIPEDA) (S.C. 2000, c. 5),</li>
            <li>Canada's Anti-Spam Legislation (CASL) (S.C. 2010, c. 23)</li>
            <li>Japan's Act on Regulation of Transmission of Specified Electronic Mail (Act No. 26 of April 17, 2002)
            </li>
            <li>any regulations having the force of law or laws in force in your or your email/SMS/phone call
                recipient's country of residence.</li>

        </ul>

        <h5>1.8 GENERAL TERMS</h5>
        <p>If you breach this AUP we may immediately suspend your access to the SGR Labs/Heliware/HeliBucket
            Software/Service. We may also terminate your and our subscription agreement for cause if you breach this
            AUP. You acknowledge we may disclose information regarding your use of any SGR Labs/Heliware/HeliBucket
            Software/Service to satisfy any law, regulation, government request, court order, subpoena, or other legal
            processes. If we make this type of required disclosure we will notify you, unless we are required to keep
            the disclosure confidential.</p>
        <p>We are not obligated to but may choose to, remove any prohibited materials and deny access to any person who
            violates this AUP. We further reserve all other rights.
        </p>

        <h5>2. CONTACT INFORMATION
        </h5>
        <p>explore@heliware.co.in</p>
        <p> Moti Vihar, Sector 41, Gurugram, Haryana</p>
        <p>+91-8860276440</p>
        <h5>3. TERMS & CONDITIONS FOR SERVICES</h5>
        <p>SGR Labs/Heliware/HeliBucket through its website and via other media (including but not limited to calls,
            chats, text messages, books, YouTube channel/videos, social media platforms, or online video consultation)
            provides Consulting/Advisory/ Solutions to its client's/customer's Software Services as per the list of
            services attached in Annexure A. </p>
        <p>The User/Customer/Client understands & agrees to indemnify SGR Labs/Heliware/HeliBucket, www.heliware.co.in &
        <a href="http://helibucket.co.in">http://helibucket.co.in</a> & its directors/staff/employees/agents against any claims whatsoever arising out of
            the Consulting/Advisory/Solutions provided to the User/Customer/Client. </p>

        <p>This Site and the information available on the Site are for informational purposes only, and should not be
            construed as investment, tax, legal, accounting, or other advice nor is it to be relied upon in making an
            investment or financial or any other decision. By using the Site, you agree to be legally bound and abide by
            the terms and conditions set forth below and elsewhere on this Site.</p>

        <p>The User/Customer/Client understands & agrees to the following terms & conditions under which the
            Consulting/Advisory/Solutions are provided by SGR Labs/Heliware/HeliBucket & its
            staff/directors/agents/employees.
        <ol type="a">
            <li>SGR Labs/Heliware/HeliBucket, <a href="https://heliware.co.in">https://heliware.co.in</a> & <a href="http://helibucket.co.in">http://helibucket.co.in</a> neither proposes nor commits
                any guarantee/warranty/promise for the success or failure or no-result or adverse result of services
                like the authenticity of the images/videos or any other result of the Software etc outlined under
                product & solution (including their sub-heads & sub categories) mentioned on the website and the
                attached Annexure A.</li>
            <li>SGR Labs/Heliware/HeliBucket, <a href="https://heliware.co.in">https://heliware.co.in</a> & <a href="http://helibucket.co.in">http://helibucket.co.in</a> will be under its rights to
                initiate any legal action/proceeding/suit against the user/client/customer who/which may indulge in
                inappropriate/illegal/unethical actions including (but not limited to)
                stalking/harassment/botheration/acts to defame/propagating false information or any material breach of
                the legal/privacy policy mentioned here or any terms which are implied & not explicitly mentioned here.
            </li>
            <li>Disclaimer on Limitations on Liability: Your use of this site and the service is at your own risk. All
                information, content, materials, and services on the website are provided to you on an “as is” and “as
                available” basis and we expressly disclaim all warranties of any kind, either expressed or implied,
                including, but not limited to, any warranty of non-infringement or any implied warranty of
                merchantability or fitness for a particular purpose or any other warranty, condition, guarantee or
                representation, whether oral, in writing or in electronic form, including but not limited to the
                accuracy or completeness of any information contained therein or provided by the service, or to
                reliability or title. SGR Labs/Heliware/HeliBucket expressly disclaims any representations, warranties,
                and/or liability, whether explicit or implied, with respect to financial aid information provided on
                this site. Any transactions undertaken on or through this site shall be at the sole risk of the user.
                You are solely responsible for any damage to your computer system or other equipment, or loss of data
                that results from such download of the SGR Labs/Heliware/HeliBucket Website/software. Neither SGR
                Labs/Heliware/HeliBucket nor any third-party information providers and vendors will be liable for any
                direct, indirect, incidental, special, consequential, punitive, or exemplary damages, including but not
                limited to, damages for loss of profits, revenue, income, goodwill, use, data, or other intangible
                losses, or damages caused by theft, unauthorized access, systems failure, or communications line
                failure, or the cost of procuring substitute goods or services, caused by the use of or inability to use
                the site, materials or any products or services provided herein, or any other matter relating to this
                site, even if SGR Labs/Heliware/HeliBucket has been advised of the possibility of such damages. </li>
            <li>We do not represent or warrant that:
                <ol type="a">
                    <li>Access to the service will be uninterrupted;</li>
                    <li>There will be no failures, errors or omissions, or loss of transmitted information; or</li>
                    <li>No viruses will be transmitted during the provision of the service.</li>
                </ol>
            </li>
            <li>We shall not be liable to you or any third parties for any direct, indirect, special consequential, or
                punitive damages arising out of your access to or inability to access the service, including for viruses
                alleged to have been obtained from the site, or your use of or reliance on the site or any of the
                information or materials available on the service, regardless of the type of claim or the nature of the
                cause of action. Except as provided by law, neither SGR Labs/Heliware/HeliBucket nor its third-party
                information providers and vendors have any responsibility to maintain the materials, products, or
                services offered on the site or to supply corrections, updates, or releases for the same. Some
                states/counties/provinces do not allow the exclusion of implied warranties or the limitation or
                exclusion of liability for incidental or consequential damages, so the above exclusions or limitations
                may not apply to you. You agree to indemnify, defend, and hold harmless SGR Labs/Heliware/HeliBucket and
                each of its respective officers, directors, employees, and agents, from and against all claims, demands,
                liabilities, damages, losses, or expenses, including attorney's fees and costs, arising out of or
                related to your improper access to or use of the Site, or any violation by you of these Terms of Use.
            </li>
            <li>You may use or download material displayed on this Site for non-commercial, personal use only. If you
                download material, you agree to retain all copyright and other proprietary notices displayed on the
                materials. You may not distribute, modify, transmit, post, or otherwise use the content of this Site for
                public or commercial purposes without our written permission. SGR Labs/Heliware/HeliBucket, in its sole
                discretion, reserves the right to refuse service, terminate accounts, and cancel orders if we believe
                that your conduct violates the Terms of Use. You agree not to disrupt, modify or interfere with the
                Software/Service in any way, and you agree not to impede or interfere with others use of the
                Software/Service. You further agree not to alter or tamper with any information or materials on, or
                associated with, the Service. You agree that you will not use our site if you are under the age of 13.
            </li>
            <li>The trademarks, service marks, designs, and logos (collectively, the “Trademarks”) displayed on this
                Site are the registered and unregistered Trademarks of SGR Labs/Heliware/HeliBucket. Use of the
                Trademarks displayed on this Site, except as provided in these Terms of Use, is strictly prohibited.
                Material on the Service is for your personal use only. The Service contains copyrighted and other
                proprietary information. You agree that you will not in any way make commercial or other unauthorized
                use, by publication, re-transmission, distribution, performance, caching, or otherwise, of material
                obtained through the Service, except as permitted by the Copyright Act or other applicable law. All
                content on the Site, including, but not limited to, text, pictures, graphics, logos, button icons, data
                compilations, images, and the selection and arrangement of such content is the exclusive property of SGR
                Labs/Heliware/HeliBucket or its licensors and is protected by Indian and international copyright laws.
                All rights not expressly granted hereby are reserved.</li>
            <li>
                Data and other materials appearing on this Site that are provided by third parties are believed by SGR
                Labs/Heliware/HeliBucket to be obtained from reliable sources, but we cannot guarantee and are not
                responsible for their accuracy, timeliness, completeness, or suitability for use. SGR
                Labs/Heliware/HeliBucket is not responsible for, and does not prepare, edit, or endorse, the content,
                advertising, products, or other materials on or available from any website owned or operated by a third
                party that is linked to this Site via hyperlink. The fact that SGR Labs/Heliware/ has provided a link to
                a third party's website does not constitute an implicit or explicit endorsement, authorization,
                sponsorship, or affiliation by SGR Labs/Heliware/HeliBucket with respect to such website, its owners,
                providers, or services. You will use any such third-party content at your own risk.
            </li>
            <li>E-mail messages may not be secure and may be intercepted by third parties. If you send us an e-mail
                containing confidential information about you, you agree that you do so at your own risk and that you
                hold SGR Labs/Heliware/HeliBucket harmless from any loss that you may suffer as a result.</li>
            <li>SGR Labs/Heliware/HeliBucket grants you a limited, revocable, non-exclusive right to create a hyperlink
                to the Site ("Link"), provided you comply at all times with the following conditions:
                <ul>
                    <li>The Link must resolve to SGR Labs/Heliware/HeliBucket page at www.heliware.co.in, unaltered in
                        any way;</li>
                    <li>The text of the Link must read “SGR Labs/Heliware/HeliBucket”, You do not have the rights to use
                        SGR Labs/Heliware/HeliBucket logo or graphic, or any other trademark, as part of the Link
                        without SGR Labs/Heliware/HeliBucket's express written permission; and</li>
                    <li>The Link and surrounding context on the linking site must not:
                        <ul>
                            <li>
                                Falsely represent or misrepresent any relationship between the linking site and SGR Labs/Heliware/HeliBucket including suggestions of affiliation, endorsement, or sponsorship
                            </li>
                            <li>
                                Portray or its affiliates, or their products or services, in a false, misleading, derogatory, or otherwise offensive manner; or
                            </li>
                            <li>Deliver content and materials in a framed environment or alter the layout, content, look, or feel of the Site.</li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
        </p>
    </div>
    

    <div className="modal fade" id="exampleModalforpop" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
        <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
            <div className="text-center mb-5 mt-3">
            <p className="fs-3 text-center fw-bold"> Our Browser Version Coming Soon </p>
            <img alt='big data sharing platform helibucket' src="https://res.cloudinary.com/dgx4bwfhd/image/upload/v1667815210/New_Project_2_1_o43jrr.png" className="pc-img mt-3"></img>
            <p className="fs-5 text-center mt-3"> Download our desktop version<br/> & get 25 GB free cloud storage </p>
            <button className="btn btn-primary rounded-pill mt-3 pe-5 ps-5" onClick={()=>window.location.href='https://heliwarestorage.s3.us-west-2.amazonaws.com/bucket_data/heli_bucket/HeliBucket_Installer-0.1.exe'}>Download Now</button>
            </div>
        </div>
        
        </div>
    </div>
    </div>

    <div className='footer-heli'>
            <div className='container'>
                <div className='row'>
                    
                    <div className='col-12 col-sm-12 col-md-6 mt-1 t-n-c-f'>
                        <a className='footer-text me-2' href='/terms-and-conditions/'>Terms & Condition</a>
                        <a className='footer-text me-2' href='/use-policy/'>Use Policy</a>
                        <a className='footer-text me-2' href='/return-policy/'>Refund Policy</a>
                        <a className='footer-text me-2' href='/contact/'>Help & Support</a>
                    </div>
                
                    <div className='col-12 col-sm-12 col-md-6'>
                        <p className=' color-aaa copyright-text'>
                        Copyright © 2023 Helibucket.co.in. All Rights Reserved.
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}
