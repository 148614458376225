import {BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import ReactGA from 'react-ga';
//import { HashRouter as Router, Route, Routes, Link, Switch } from 'react-router-dom'; 
import './assets/styles/App.css';
import Home from './components/instant_file_input';
import DownloadPage from './components/DownloadPage';
import LandingPage from './components/LandingPage';
import PrivateFileShow from './components/PrivateFileShow'
import PrivateFileShowPublic from './components/PrivateFileShowPublic'
import About from './components/About';
import Contact from './components/Contact';
import UsePolicy from './components/AccectableUsePolicy';
//import LoginPage from './components/LoginPage';
import Login from './components/Login'
import Dashboard from './components/Dashboard';
import PremiumDashboard from './components/PremiumDashboard';
import Register from './components/SignUp';
import TermsAndCondition from './components/TermsAndCondition';
// import ProfilePage from './components/ProfilePage';
import ReturnPolicy from './components/ReturnPolicy';
import Verification from './components/Verification';
import CreateBucket from './components/CreateBucket';
import PrivacyPolicy from './components/PrivacyPolicy';
import BinPage from './components/BinPage';
import BinPageNew from './components/BinPageNew'
//import Home2 from './components/Home2';
const TRACKING_ID = "G-FNLFX7K7KX";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>
    <Router>
    <Routes>
      <Route exact path='/upload/' element={<Home/>}></Route>
      <Route exact path='/'  element={< LandingPage />}></Route>
      <Route exact path='/about/' element={< About />}></Route>
      <Route exact path='/contact/' element={< Contact />}></Route>
      <Route exact path='/login/' element={< Login />}></Route>
      <Route exact path='/dashboard/' element={< Dashboard />}></Route>
      <Route exact path='/dashboard-premium/' element={< PremiumDashboard />}></Route>
      <Route exact path='/private-share/' element={< PrivateFileShow />}></Route>
      <Route exact path='/download/:keyId' element={< DownloadPage />}></Route>
      <Route exact path='/register/' element={< Register />}></Route>
      <Route exact path='/use-policy/' element={< UsePolicy />}></Route>
      <Route exact path='/terms-and-conditions/' element={< TermsAndCondition />}></Route>
      <Route exact path='/privacy-policy/' element={< PrivacyPolicy />}></Route>
      <Route exact path='/return-and-refund-policy/' element={< ReturnPolicy />}></Route>
      <Route exact path='/verification/' element={<Verification/>}></Route>
      <Route exact path='/create-bucket/' element={<CreateBucket/>}></Route>
      <Route exact path='/private-share-public-url/' element={<PrivateFileShowPublic/>}></Route>
      <Route exact path ="/sitemap.xml" element={<sitmap/>}></Route>
      {/* <Route exact  path="/bin-page/" element={<BinPage/>}></Route> */}
      <Route exact  path="/bin-page/" element={<BinPageNew/>}></Route>
      
      
    </Routes>
    </Router>

  </>

  );
}

export default App;
