import React from 'react'
import {Link} from "react-router-dom";
  import certificate from "../assets/images/cmmi1.png";
  import ogc1 from "../assets/images/ogc1.png";
  import map from "../assets/images/map-01.png"
export default function FooterNew() {
  return (
    <div>
      <div className="">
        <footer className="text-lg-start footer-sec">
          {/* <section className="container pb-3 ">
              <div className="row mt-3">
                <div className="col-12 col-sm-12  col-lg-6 col-xl-6 mx-auto mb-4">
                <div><img src="https://res.cloudinary.com/derifbjbg/image/upload/v1712576445/ff_zi2gf2.webp" style={{width:"250px", height:"auto"}} /></div>
                <div className="d-flex mt-4">
                  <i className="bi bi-geo-alt-fill pe-2 " />
                  <p className="text-black">
                    24 Square, South city-1, Sector-41, Gurugram, Haryana 122022,
                    India.{" "}
                  </p>
                </div>
                <div className="d-flex mt-4">
                  <i className="bi bi-geo-alt-fill pe-2" />
                  <p className="text-black">
                    {" "}
                    Unit NO 7WS4D, 7th Floor, West Block Mani Casadona, Plot No.
                    IIF/04, Action Area- IIF, Newtown,
                    <br /> Kolkata, West Bengal, 700156, India.
                  </p>
                </div>
                <button className='footer-address' style={{ borderRadius: 13, border: "none" }}>
                  <Link to="https://goo.gl/maps/a9DY6N35xzPCTCWdA" target="_blank">
                    <img src={map} className="footer-address-img" />
                  </Link>
                </button>
                </div>
                <div className="col-sm-12 col-md-2  col-12 col-lg-2 col-xl-2 mx-auto mb-4 ">
                  <h6 className="text-uppercase fw-bold footer-heading" style={{ color: "#0056A2", fontSize: 15 }}>
                    Products 
                  </h6>
                  <p className="footer-address">
                    <Link to="https://heliware.co.in/app/helimapper" target="_blank">
                      Heli Mapper
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="https://heliware.co.in/app/heliai" target="_blank">
                      Heli AI
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="https://heliware.co.in/app/heli3D" target="_blank">
                      Heli 3D
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="https://helibucket.co.in/" target="_blank">
                      Heli Bucket
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="#" target="_blank">
                      Bhumi Portal
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="#" target="_blank">
                      Bhumi Gps
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="#" target="_blank">
                      Bhumi Server
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="https://www.npmjs.com/package/heliware-js" target="_blank">
                      Heli JS
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="https://heliware.co.in/app/network" target="_blank">
                      Heli Network
                    </Link>
                  </p>
                </div>

                <div className="col-sm-12 col-md-2  col-12 col-lg-2 col-xl-2 mx-auto mb-4">
                  <h6 className="text-uppercase footer-heading fw-bold" style={{ color: "#0056A2", fontSize: 15 }}>
                    Solutions
                  </h6>
                  <p className="mt-3 footer-address">
                    <Link
                      to="https://heliware.co.in/app/solar"
                      target="_blank"

                    >
                      Solar
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link
                      to="https://heliware.co.in/app/construction"
                      target="_blank"
                    >
                      Construction
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="https://heliware.co.in/app/logistic"target="_blank">
                      Logistic
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="https://heliware.co.in/app/retail"
                      target="_blank">
                      Retail
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="https://heliware.co.in/app/retail"
                      target="_blank">
                      Defence
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="https://heliware.co.in/app/retail" target="_blank">
                      Smart City
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="https://heliware.co.in/app/retail" target="_blank">
                      Agriculture
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="https://heliware.co.in/app/retail" target="_blank">
                      Automotive
                    </Link>
                  </p>
                </div>
                <div  className="col-sm-12 col-md-2 col-12 col-lg-2 col-xl-2 mx-auto mb-md-0 mb-4" >
                  <h6 style={{ color: "#0056A2", fontSize: 15 }} className="text-uppercase footer-heading fw-bold">
                    Quick Links
                  </h6>
                    <p className="mt-3 footer-address">
                    <Link
                      to="/about"
                      target="_blank">
                      About Us
                    </Link>
                  </p>
                  <p className="footer-address">
                    <Link to="/contact">
                      Contact Us
                    </Link>
                  </p>
                  <p className="footer-address mb-2">
                    <Link to="/privacy-policy" target="_blank"
                    >
                      Privacy Policy
                    </Link>
                  </p>
                  <p className="footer-address mb-2">
                    <Link to="/terms-and-conditions" target="_blank"
                    >
                      Terms & Condition
                    </Link>
                  </p>
                  <p className="footer-address mb-3">
                    <Link to="/return-and-refund-policy" target="_blank"
                    >
                      Return & Refund Policy
                    </Link>
                  </p>

                  <div className="" style={{ color: "#0056A2", fontSize: 15 }}>
                    <b>Contact</b>
                  </div>
                  <div className=''>
                    <div className="d-flex footer-address">
                      <i className="bi bi-envelope-fill icon footer-ico pe-1" />
                      <p className="text-black">
                      <Link
                        to="mailto:rajan@heliware.co.in"
                        target="_blank"
                      >
                        rajan@heliware.co.in
                        </Link>
                      </p>
                    </div>
                    <div className="d-flex footer-address">
                      <i className="bi bi-envelope-fill icon footer-ico pe-1" />
                      <p className="text-black">
                      <Link
                        to="mailto:explore@heliware.co.in"
                        target="_blank"
                      >
                        explore@heliware.co.in
                        </Link>
                      </p>
                    </div>
                    <div className="d-flex footer-address">
                      <img src={certificate} className="footr-img-1" />
                      <img src={ogc1} className="footr-img-1 ms-3" />
                    </div>
                    <div className=" mt-4  d-flex footer-address">
                      <Link
                        to="https://www.facebook.com/people/Heliware/100063830712507/"
                        target="_blank"
                      >
                        <i className="bi bi-facebook facebook-icon" />
                      </Link>
                      <Link
                        to="https://twitter.com/i/flow/login?redirect_after_login=%2FHeliware2"
                        target="_blank"
                      >
                        <i className="bi bi-twitter twitter-icon icon-pad" />
                      </Link>
                      <Link
                        to="https://www.linkedin.com/company/sgr-labs/"
                        target="_blank"
                      >
                        <i className="bi bi-linkedin link-icon icon-pad" />
                      </Link>
                      <Link
                        to="https://www.instagram.com/accounts/login/?next=%2Fheliware%2F"
                        target="_blank"
                      >
                        <i className="bi bi-instagram Insta-Icon icon-pad" />
                      </Link>
                      <Link
                        to="https://www.youtube.com/channel/UC4cgcqlkoSXVhi47CQHbZjQ"
                        target="_blank"
                      >
                        <i className="bi bi-youtube youtube-icon icon-pad" />
                      </Link>
                    </div>
                  </div>
                  
                </div>
            </div>
          </section> */}
          {/* <div className="copyright text-center p-3">Copyright © 2023 Heli Bucket</div> */}
          <div className="text-center mt-4 copyright ftcontent">
            Copyright © 2024 by
              folder&files.com
            All Rights Reserved.
          </div>
        </footer>
      </div> 

    </div>
  )
}
