import React, { useEffect, useState } from 'react'
import createFolder from "../assets/images/create_folder.png"
import {useNavigate} from "react-router-dom";
// SomeReactComponent.js

import { WebUrl } from '../helpers/constant';
export default function CreateBucket() {
const getEmailFromCookie = localStorage.getItem('email');
const StorageType = localStorage.getItem('storageType');
const [BucketName , setBucketname] =useState("")
const navigate = useNavigate();
const [bucketLoader , setBucketLoader]=useState(false)
// const handelBuckename= (e) =>{
//   const buckenName = e.target.value;
//   setBucketname(buckenName)
// }


const userEffect =() =>{
  if (getEmailFromCookie === null){
        navigate('/login');
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: getEmailFromCookie })
    };
    fetch(`${WebUrl}/file-detail-list-with-decoretor/`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('=====>',data);
            // const messege = data['msg']
            if (data['msg'] === "You have not any Bucket"){
              navigate('/create-bucket')
            }
            else if (data["message"] === "please enter different bucket name"){
              navigate('/create-bucket')
            }
            else{
              // navigate('/dashboard-premium')
              StorageType=== "premimum"?  navigate('/dashboard-premium') : navigate('/dashboard');
            }
            


    });
}



// const userEffect = ()=>{
//   //  if (getEmailFromCookie === null){
//   //       navigate('/login');
//   // console.log(getEmailFromCookie)

//   //   }
// }







const CreateBucketName =()=>{
  setBucketLoader(true)
  if (BucketName.length === 0){
    alert("please enter valid bucket Name")
    setBucketLoader(false)
  }
  else{
    console.log(BucketName , getEmailFromCookie)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: getEmailFromCookie ,bucket_name : BucketName})
    };
    fetch(`${WebUrl}/create-bucket/`, requestOptions)
      .then(response => response.json())
      .then(data => {
          console.log('=====>',data);
          if (data["message"]=== "please enter different bucket name"){
            navigate('/create-bucket')
          }
          else{
            StorageType=== "premimum"?  navigate('/dashboard-premium') : navigate('/dashboard');
          }
          setBucketLoader(false)
          // navigate('/dashboard-premium')
        });
  }
  
}
useEffect(()=>{
  userEffect();
},[]);
  return (
    <React.Fragment>
    <div  className='container  ' style={{'backgroundColor':"#ffffff"}} >
      
      <div className=' text-center mt-5 pt-5 fs-2' style={{'color':'#000'}}>Create Your Bucket</div>
      <div className='text-center mt-3 '><img src={createFolder} /></div>
      <div className='text-center' >
        <div className="input-group mb-3 mt-3 w-25 m-auto" >
            <input type="text" className="form-control" required onChange={(e)=>setBucketname(e.target.value)} value={BucketName} placeholder="enter your bucket name" aria-label="Enter Your Bucket" aria-describedby="basic-addon2"/>
            <span className="input-group-text" style={{"cursor":"pointer"}} onClick={CreateBucketName} id="basic-addon2"><i class="bi bi-arrow-right"></i><span class={`spinner-border spinner-border-sm ${bucketLoader ? null:'d-none' } `} role="status" aria-hidden="true"></span></span>
        </div>
      </div>
    </div>
            
    </React.Fragment>
  )
}
