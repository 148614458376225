import React from 'react'
// import Swal from 'sweetalert2';
import Rocket from '../assets/images/Rocket.json'
import Lottie from 'react-lottie-player'
export default function ProgressBar({Progress ,uploadFileName , FileQuantityUpload, presentlyUploaded }) {
  return (
    <div className='progress-bari p-3 rounded-2 position-absolute top-50 start-50 translate-middle'>

      <div  className='progress_div'>
        <div className='d-flex align-items-center justify-content-center postion-absolute'>
          <Lottie loop animationData={Rocket} play style={{ width: 200, height: 200 ,'marginButtom':'-20px' }}/>            
        </div>
        <h5 className='align-item-center text-center text-light'>uploading... {presentlyUploaded}/{FileQuantityUpload}</h5>
        {/* <p className='text-limit '>{uploadFileName}</p> */}
        <div className={` progress`} style={{"height":"10px"}} role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-bar" style={{'width':`${Progress}%`}}>{Progress}%</div>
              
        </div>
      </div>
      {/* <div className="modal" tabIndex={-1}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <p>Modal body text goes here.</p>
            </div>
          </div>
        </div>
      </div> */}
        
    </div>
  )
}
