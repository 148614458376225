import React, { useState } from 'react'
import loader_new from '../assets/images/loader_new.gif';
import Swal from 'sweetalert2';
import '../assets/styles/dashboard.css';
import sendIcon from '../assets/images/send.svg';
import { WebUrl } from '../helpers/constant';

export default function CommentSection({FileInfo,collaboration,CollaboerationLoader , clearCollaborationState , chatavilable ,commentsinfo ,share_email,user_name , viewComment}) {
const [comment , setComment] =useState('')

function getRandomColor() {
  // Generate random values for red, green, and blue
  const randomRed = Math.floor(Math.random() * 256);
  const randomGreen = Math.floor(Math.random() * 256);
  const randomBlue = Math.floor(Math.random() * 256);

  // Create a CSS color string using the random values
  const randomColor = `rgb(${randomRed},${randomGreen},${randomBlue})`;

  return randomColor;
}
const randomColor = getRandomColor()
const handelComment =(e)=>{
  // console.log(e.target.value)
  setComment(e.target.value)
};
const extractLastParts =(filenames) =>{
  const parts = filenames.split('/');
  const lastPart = parts[parts.length - 1];
  return lastPart;
  
}
// const utcToLocalTime = (utcTime) => {
//   // const localTime = new Date(utcTime).toLocaleString();
  
//   return  <TimeAgo date={utcTime} />;
// };
// function toLocalISOString(date) {
//   var tzoffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
//   var localISOTime = (new Date(date - tzoffset)).toISOString().slice(0,-1);
//   return localISOTime;
// }


// Create an array of month names
const monthNames = {
  "Jan":'1', "Feb":'2', "Mar":'3', "Apr":'4', "May":'5', "Jun":'6',
  "Jul":'7', "Aug":'8', "Sep":'9', "Oct":'10', "Nov":'11', "Dec":'12'

};
function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
const ConvertTimeStyle =(text) =>{
  let date = text.split(",")
  let DateSplit = date[0].split("/")
  let Month = DateSplit[1]
  let day = DateSplit[0]
  let year = DateSplit[2].slice(2);
  let time = date[1].slice(0, -6)+date[1].slice(-2)
  let timeString =`${time} ${day} ${getKeyByValue(monthNames ,Month)}, ${year}`
  // console.log(timeString)
  return timeString
  
}
const utcToLocalTime = (utcTime) => {
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // console.log(utcTime ,timeZone)
  const localTime = new Date(utcTime+"Z").toLocaleString(undefined, timeZone);
  // console.log(localTime)
  let timestring =ConvertTimeStyle(localTime)
  return  timestring
};
const postComment =()=>{
  // console.log(comment, FileInfo[0].fileName , share_email , user_name , comment)
  if (comment.length === 0){
    Swal.fire({
      title: 'Please add the comment',
      icon: 'error',
      // confirmButtonText: 'OK'
      });
  }
  else{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ file_path: FileInfo[0].fileName  ,share_email_list :share_email ,
        user_name :user_name ,message :comment })
  };
  fetch(`${WebUrl}/add-comment-by-user/`, requestOptions)
  .then(response => response.json())
  .then(data=>{
      setComment("")
      // console.log(data)
      let itemWiseFileName = FileInfo[0].fileName.split("/")
      // console.log(itemWiseFileName[itemWiseFileName.length - 1]) 
      viewComment(itemWiseFileName[itemWiseFileName.length - 1])
  })
  }
}
  return (
        <div className="commentBoxBgDiv offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex={-1} id="CommentSection" aria-labelledby="CommentSection" >
            <div className="comment-header" style={{'marginTop':'-40px' , 'right':'0',"display":"flex"}}>
              <button type="button" onClick={clearCollaborationState} class="bi bi-arrow-left hederIconStyle" data-bs-dismiss="offcanvas" aria-label="Close" />
              <button type="button"  class="bi bi-chat-left-text-fill hederIconStyle" />
              <div  style={{'fontSize':"large"}}><i ></i></div>
              <div><h3 className='hederIconStyleForText'>Commenting</h3></div>
            </div>
          <div className= {`${CollaboerationLoader ? "d-flex position-absolute top-50 start-50 translate-middle":"d-none"}`}>
            <img className='file-icon-list ' style={{"width":"80px" , "height":"auto"}} src={loader_new} />
          </div>
          <div className={` ${CollaboerationLoader ? "d-none":null}`}  >
          {chatavilable === false ? (
              <div className='align-item-center d-flex justify-content-center '>
                <p className='p-2 bg-cyan'>File not shared with anyone.</p>
              </div>
            ) : (
            <div className="offcanvas-body">
                <div  className='comment_body m-2' >
                <h5 className="offcanvas-title ms-1" id="offcanvasScrollingLabel">
                    <section>
                      <div className="container py-1">
                        <div className="row d-flex justify-content-center">
                          <div className="col-12">
                            <div className="card-body" class="overflow-y-auto" style={{"height":"68vh"}} >
                              {commentsinfo.length === 0 ? (
                              <div>
                                  <div className='align-item-center d-flex justify-content-center '>
                                      <p className='p-2 bg-cyan'>No comments yet.</p>
                                  </div>
                              </div>
                        ) : (
                            commentsinfo.map((item) => (
                                <div>
                                  {item.username === share_email ? (
                                    <div className='mb-4'>
                                    <div className="d-flex flex-row justify-content-end" id={item.commenting_id}>
                                      <div className="p-3 me-1 own-comment">
                                        <p className="small mb-0 comments">{item.comment}</p>
                                      </div>
                                      <div>
                                        <i  className="bi bi-person-circle"></i>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-end">
                                        <p className='me-4' style={{"fontSize":"9px"}}>{utcToLocalTime(item.create_time)}</p>
                                    </div>
                                    </div>
                                    
                                  ) : (
                                    <div className="mb-4">
                                    <div className="d-flex flex-row justify-content-start">
                                        <p className='ms-4 user-name'style={{'color':randomColor}} >{item.name}</p>
                                    </div>
                                    <div className="d-flex flex-row justify-content-start" id={item.commenting_id}>
                                      <div>
                                        <i className="bi bi-person-circle"></i>
                                      </div>
                                      <div className="p-3 ms-1 user-comment" >
                                        <p className="small mb-0 comments">{item.comment}</p>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-center">
                                        <p className='ms-5' style={{"fontSize":"9px"}}>{utcToLocalTime(item.create_time)}</p>
                                    </div>
                                    </div>
                                  )}
                                </div>
                              )))}
                              </div>
                              <div className="form-outline ">
                              <div className="d-flex mt-2 mb-2">
                                  <div className="commentBoxDiv ">
                                    <input onChange={handelComment} value={comment} type="text" placeholder='Write your comment' className="commentBox "   />
                                    <button >
                                      <img onClick={()=>postComment()} style={{"backgroundColor":"transparent"}} src={sendIcon}/>
                                    </button>
                                  </div>
                                </div>

                              </div>
                          </div>
                          
                        </div>
                        
                      </div>
                      
                    </section> 
                    
                </h5>
                </div>
                <div className="Crs_styleDiv">
                <p className='Crs_style'>Powered by Heliware CRS</p>
                </div>
            </div>)}
          </div>
        </div>
  )
}
