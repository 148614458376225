import React from 'react';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton
  } from "react-share";
  import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    PinterestIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    WhatsappIcon
  } from "react-share";
const ShareButtons = ({shareLinkUrl}) => {
    
    // const shareLinkUrl = "https://helibucket.co.in"
  return (
    <div className="owner-viewer-2 text-center justify-content-center mt-3">
      <p className="mt-2" style={{ fontFamily: "Inter" }}>
        You can also share this link on social media
      </p>
      <div className="d-flex text-center justify-content-center mt-2">

        <FacebookShareButton url={shareLinkUrl} >
            <FacebookIcon size={32} round={true} />
        </FacebookShareButton>

        <EmailShareButton url={shareLinkUrl} >
            <EmailIcon size={32} round={true} />
        </EmailShareButton>

        <LinkedinShareButton url={shareLinkUrl} >
            <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>

        <PinterestShareButton url={shareLinkUrl} >
            <PinterestIcon size={32} round={true} />
        </PinterestShareButton>

        <RedditShareButton url={shareLinkUrl} >
            <RedditIcon size={32} round={true} />
        </RedditShareButton>

        <TelegramShareButton url={shareLinkUrl} >
            <TelegramIcon size={32} round={true} />
        </TelegramShareButton>

        <TumblrShareButton url={shareLinkUrl} >
            <TumblrIcon size={32} round={true} />
        </TumblrShareButton>

        <TwitterShareButton url={shareLinkUrl} >
            <TwitterIcon size={32} round={true} />
        </TwitterShareButton>

        <WhatsappShareButton url={shareLinkUrl} >
            <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>

      </div>
    </div>
  );
};

export default ShareButtons;