import React ,{useState, useEffect ,useCallback} from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import swal from 'sweetalert';
import uuid from 'react-uuid';
import JSZip from 'jszip';
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";
import Dropzone from "react-dropzone";
import boxLoaderPic from '../assets/images/box-loader.gif';
import copy from "copy-to-clipboard";
import { useNavigate} from 'react-router-dom';
import { WebUrl } from '../helpers/constant';
const S3_BUCKET ='heli-bucket-instant-transfer';
const REGION ='us-east-2';
const creds = {
    accessKeyId: process.env.REACT_APP_S3_KEYID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY
}


const Home = () => {
    useEffect(() => {
        const timer = setTimeout(() => {
          //setCount('Timeout called!');
          //alert('jfsslkdghjdfhg');
        //   document.getElementById('OpenTermsPop').click();
          
        }, 1000);
        return () => clearTimeout(timer);
      }, []);
    const navigate = useNavigate();
    const Open = (id) => { 
          document.getElementById(id).click();
        }
        
   // const {getRootProps, getInputProps, isDragActive} = useDropzone({handleDrop})
    const [showResults, setShowResults] = React.useState(false);
    const [showList, setShowList] = React.useState(false);
    const [seeEmailForms, setSeeEmailForms] = React.useState(false);
    const [uploadSec, setUploadSec] = React.useState(false);
    const [successSec, setSuccessSec] = React.useState(false);
    const [linkSec, setLinkSec] = React.useState(false);
    const [verifyText, setverifyText] = React.useState('verify email');
    const [showLink, setShowLink] = React.useState(false);
    const [disableToggle, setDisableToggle] = React.useState(false);
    const [emailTransferField, setEmailTransferField] = React.useState(false);
    const [OTPField, setOTPField] = React.useState(false);
    const [showShareLink, setShowShareLink] = React.useState('');
    const [yourEmail, setYourEmail] = React.useState('');
    const [yourOTP, setYourOTP] = React.useState('');
    const [OTPwrongText, setOTPwrongText] = React.useState(false);
    const [boxLoader, seBoxLoader] = React.useState(false);
    const [receiverEmail, setReceiverEmail] = React.useState('');
    const [OTPinput, setOTPinput] = React.useState('');
    //const onClick = () => setShowResults(true);
    const [length_file_array , setlength_file_array] = useState(0);
    const [Unit_total , setUnit_total] = useState('KB');
    var [size_file_array , setsize_file_array] = useState(0);
    const [progress , setProgress] = useState(0);
    const [uprogress , setUprogress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [dragFilesList, setDragFilesList] = useState(null); 
    const [formSec, setFormSec] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [totalUpload, setTotalUpload] = useState([]);
    const [copyBtnTxt, setCopyBtnTxt] = useState('Copy');
    const [copyBtnColor, setCopyBtnColor]=useState(false);
    const files = [];
    const ListFileArr =[];

    var workingUrl = WebUrl;

    // const ListOfItem = (a) => {
    //     for(var i = 0; i < a.length; i++){
    //         const node = document.createElement("p");
    //         const node_i = document.createElement('i');
    //         if(a[i].indexOf('.') > -1){
    //             node_i.className = "bi bi-file-earmark-fill";
    //         }else{
    //             node_i.className = "bi bi-folder-fill";
    //         }
            
    //         node.appendChild(node_i);
    //         const textnode = document.createTextNode(' ' +a[i] );
    //         const spanNode = document.createElement("span");
    //         const INode = document.createElement("i");
    //         INode.className = "bi bi-trash";
    //         spanNode.appendChild(INode);
    //         node.className = "file-card-name";
    //         node.appendChild(textnode);
    //         node.appendChild(spanNode);
    //         const div = document.getElementById('file-card');
    //         div.appendChild(node);
            
    //     }
    // }
    const ListOfItem = (a) => {
        for(var i = 0; i < a.length; i++){
            const node = document.createElement("p");
            const node_i = document.createElement('i');
            if(a[i].indexOf('.') > -1){
                node_i.className = "bi bi-file-earmark-fill";
            }else{
                node_i.className = "bi bi-folder-fill";
            }
            
            node.appendChild(node_i);
            const textnode = document.createTextNode(' ' +a[i] );
            // const spanNode = document.createElement("span");
            // const INode = document.createElement("i");
            // INode.className = "bi bi-trash";
            // spanNode.appendChild(INode);
            node.className = "file-card-name";
            node.appendChild(textnode);
            // node.appendChild(spanNode);
            const div = document.getElementById('file-card');
            div.appendChild(node);
            
            // Add an event listener to the trash icon
            // INode.addEventListener('click', function() {
            //     // Remove the item from the array
            //     a.splice(i, 1);
            //     // Remove the node from the DOM
            //     div.removeChild(node);
            // });
        }
    }
    
  
    const seeEmailForm = () =>{
        setSeeEmailForms(true);
    } //http://127.0.0.1:8080/send-otp
    const yourEmailInput = (e) =>{
        const email = e.target.value;
        setYourEmail(email);
    }
    const handleTitleIp = (e) =>{
        const title = e.target.value;
        setTitle(title);
    }
    const handleDescription = (e) =>{
        const description = e.target.value;
        setDescription(description);
    }
    const receiverEmailInput = (e) =>{
        const email = e.target.value;
        setReceiverEmail(email);
    }
    const userOTPInput = (e) =>{
        const otp = e.target.value;
        setOTPinput(otp);
    }
    const handleDrop = acceptedFiles => {
        seBoxLoader(true);
        if(selectedFile==null){
            var v=0;
            const fileList = acceptedFiles ;
            
            
            
            for (var i = 0; i < fileList.length; i++) {
                //const file = fileList.item(i)
                const file = fileList[i]
                
                files.push(file)
                v += file.size;
                const nameArr = file.path.split('/');
                if (nameArr.length>1){
                    if(!ListFileArr.includes(nameArr[1])){ 
                        ListFileArr.push(nameArr[1]);
                    }
                }else{
                    if(!ListFileArr.includes(file.name)){ 
                        ListFileArr.push(file.name);
                    }
                }
                
            }
            if(v<1024*1024*1024*5){
                setShowList(true);
                if(v>1024 && v<1024*1024){
                    v = parseFloat(v/1024).toFixed(2);
    
                }else if(v>(1024*1024) && v<1024*1024*1024){
                    setUnit_total('MB');
                    v = parseFloat(v/(1024*1024)).toFixed(2) ;
                }else if(v>(1024*1024*1024)){
                    setUnit_total('GB');
                    v = parseFloat(v/(1024*1024*1024)).toFixed(2) ;
                }
                setSelectedFile(files);
                setlength_file_array(files.length);
                setsize_file_array(v);
                console.log('selectedFile:', files)
                console.log('--->', files.length)
                setDragFilesList(null);
                const myTimeout = setTimeout(myGreeting, 4000);
                function myGreeting() {
                    seBoxLoader(false);
                    ListOfItem(ListFileArr);
                }
            }else{
                swal("Sorry!", "You exceed the limit 5gb!", "warning");
                seBoxLoader(false);
            }
            
        }else{
            const fileList = acceptedFiles ;
            var val =0;
            var value =0;
            for (var j = 0; j < fileList.length; j++) {
                
                const file = fileList[j]
                selectedFile.push(file);
                val += file.size;
                const nameArr = file.path.split('/');
                if (nameArr.length>1){
                    if(!ListFileArr.includes(nameArr[1])){ 
                        ListFileArr.push(nameArr[1]);
                    }
                }else{
                    if(!ListFileArr.includes(file.name)){ 
                        ListFileArr.push(file.name);
                    }
                }

            }
            if(Unit_total === 'KB'){
                value =  Math.round(size_file_array*1024) + val;
            }else if(Unit_total === 'MB'){
                value =  Math.round(size_file_array*(1024*1024)) + val;
                console.log('value: ', value);
            }else if(Unit_total === 'GB'){
                value =  Math.round(size_file_array*(1024*1024*1024)) + val;
            }
            if(value<1024*1024*1024*5){
                if(value>1024 && value<1024*1024){
                    value = parseFloat(value/1024).toFixed(2);

                }else if(value>(1024*1024) && value<1024*1024*1024){
                    setUnit_total('MB');
                    value = parseFloat(value/(1024*1024)).toFixed(2) ;
                }else if(value>(1024*1024*1024)){
                    setUnit_total('GB');
                    value = parseFloat(value/(1024*1024*1024)).toFixed(2) ;
                }

                console.log(selectedFile);
                console.log(size_file_array);
                setlength_file_array(selectedFile.length);
                setsize_file_array(value);
                
                const myTimeout = setTimeout(myGreeting, 4000);
                function myGreeting() {
                    seBoxLoader(false);
                    ListOfItem(ListFileArr);
                }
            }else{
                swal("Sorry!", "You exceed the limit 5gb!", "warning");
                seBoxLoader(false);
                console.log('upload less than 5gb!');
            }
        }
        
        
        

    };

    const UploadComplete = () =>{
        totalUpload.push('a')
        
    
        console.log('---------->yesyesyes')
        console.log('-->',totalUpload);
        console.log('--->',selectedFile.length)
        if (totalUpload.length === selectedFile.length){
            setUploadSec(false);
            setSuccessSec(true);
            if(yourOTP==''){
                setLinkSec(true);
            }else{
                shareFilesthoughEmail(showShareLink);
                
            }
        }
    
    }
    //setFileNames(acceptedFiles.map(file => file.name));
    const sendVerificationMail = () =>{
        setverifyText('check your email');
        setOTPField(true);
        setFormSec(true);
        setShowList(false);
        setShowLink(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ to_email: yourEmail })
            };
            fetch(`${workingUrl}/send-otp`, requestOptions)
                .then(response => response.json())
                .then(data => setYourOTP(data['otp']));
        
    }
    const shareFilesthoughEmail = (e) => {
        // console.log(showShareLink);
        // console.log(OTPinput);
        // console.log(yourOTP);
        
        //setShowLink(true);
        if(yourOTP == OTPinput){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ your_email: yourEmail, title:title, description:description, receier_email:receiverEmail, download_link:e })
                };
            const requestToDataAPI = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ sender_email: yourEmail, data_size:size_file_array, receiver_email:receiverEmail, data_unit:Unit_total })
                };
                fetch(`${workingUrl}/send-email-link`, requestOptions)
                    .then(response => response.json())
                    .then(data => Open('details-btn'));
                fetch(`${workingUrl}/save-user-info/`, requestToDataAPI)
                .then(response => response.json())
                .then(data => console.log('data save'));
            //uploadFile(selectedFile)Open('details')
        }else{
            //console.log('not same');
        }
    }
    const verifyOTPandUpload = () =>{
        if(yourOTP == OTPinput){
            upload(selectedFile);
        }else{
            setOTPwrongText(true);
        }
    }
    const emailTransferBtn = () =>{
        setEmailTransferField(true);
        setDisableToggle(true);
    }
    const directTransfer = () =>{
        setDisableToggle(true);
    }




    const handleFolderInput = (e) => {
        if(selectedFile==null){
            var v=0;
            const fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files ;
            for (var i = 0; i < fileList.length; i++) {
                const file = fileList.item(i)
                //const file = fileList[i]
                
                files.push(file)
                v += file.size;
                const nameArr = file.webkitRelativePath.split('/');
                if (nameArr.length > 1){
                    if(!ListFileArr.includes(nameArr[0])){ 
                        ListFileArr.push(nameArr[0]);
                    }
                }else{
                    if(!ListFileArr.includes(file.name)){ 
                        ListFileArr.push(file.name);
                    }
                }
                
            }
            if(v<1024*1024*1024*5){
                setShowList(true);
                if(v>1024 && v<1024*1024){
                    v = parseFloat(v/1024).toFixed(2);
                }else if(v>(1024*1024) && v<1024*1024*1024){
                    setUnit_total('MB');
                    v = parseFloat(v/(1024*1024)).toFixed(2) ;
                }else if(v>(1024*1024*1024)){
                    setUnit_total('GB');
                    v = parseFloat(v/(1024*1024*1024)).toFixed(2) ;
                }
                setSelectedFile(files);
                setlength_file_array(files.length);
                setsize_file_array(v);
                console.log('selectedFile:', files)
                console.log('--->', files.length)
                ListOfItem(ListFileArr);
                setDragFilesList(null);
            }else{
                swal("Sorry!", "You exceed the limit 5gb!", "warning");
            }
            
        }else{
            const fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files ;
            var val =0;
            var value =0;
            for (var j = 0; j < fileList.length; j++) {
                
                const file = fileList.item(j)
                selectedFile.push(file);
                val += file.size;
                const nameArr = file.webkitRelativePath.split('/');
                if (nameArr.length>1){
                    if(!ListFileArr.includes(nameArr[0])){ 
                        ListFileArr.push(nameArr[0]);
                    }
                }else{
                    if(!ListFileArr.includes(file.name)){ 
                        ListFileArr.push(file.name);
                    }
                }

            }
            // if(Unit_total === 'KB'){
            //     value =  Math.round(size_file_array*1024) + val;
            // }else if(Unit_total === 'MB'){
            //     value =  Math.round(size_file_array*(1024*1024)) + val;
            //     console.log('value: ', value);
            // }else if(Unit_total === 'GB'){
                
            //     value =  Math.round(size_file_array*(1024*1024*1024)) + val;
            // }
            value = Unit_total === 'KB' ? Math.round(size_file_array * 1024) + val :
                Unit_total === 'MB' ? Math.round(size_file_array * 1024 * 1024) + val :
                Unit_total === 'GB' ? Math.round(size_file_array * 1024 * 1024 * 1024) + val :
                val;

            if(value<1024*1024*1024*5){
                if(value>1024 && value<1024*1024){
                    value = parseFloat(value/1024).toFixed(2);

                }
                else if(value>(1024*1024) && value<1024*1024*1024){
                    setUnit_total('MB');
                    value = parseFloat(value/(1024*1024)).toFixed(2) ;
                }
                else if(value>(1024*1024*1024)){
                    setUnit_total('GB');
                    value = parseFloat(value/(1024*1024*1024)).toFixed(2) ;
                }
                console.log(files);
                console.log(selectedFile)
                console.log(size_file_array);
                setlength_file_array(selectedFile.length);
                setsize_file_array(value);
                ListOfItem(ListFileArr);
            }else{
                // swal('upload less than 5gb!');
                swal("error!", "upload less than 5gb!!", "error");

            }
        }
        
    }
    const upload = (files) => {
        const zip = new JSZip();
        const uniqid = uuid();
        setUploadSec(true);
        setFormSec(true);
        setShowList(false);
        setOTPField(false);
        setShowLink(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data_size:size_file_array , data_unit:Unit_total })
            };
            fetch(`${workingUrl}/instant-transfer-info/`, requestOptions)
                .then(response => response.json())
                .then(data => console.log('uuuuuu'));
        
        
        var total = 0;
        //setProgress(0);
        const gen_lnk = `${workingUrl}/download/`+uniqid;
        
        if(Unit_total==='GB' && size_file_array>2){
            files.forEach((file) => {
                if(file.webkitRelativePath==='' ){
                    if(file.path!==''){
                    var file_path=uniqid+'/'+file.name; 
                    
                    }else{
                        var file_path=uniqid+'/'+file.name;
                        
                    }
                }else{
                    var file_path=uniqid+'/'+file.webkitRelativePath;
                    
                }
                try {
                    const parallelUploads3 = new Upload({
                        client: new S3Client({region:'us-east-2', credentials:creds }),
                        params: { Bucket:'heli-bucket-instant-transfer', Key:file_path, Body:file },
                        leavePartsOnError: false, // optional manually handle dropped parts
                    });
            
                    parallelUploads3.on("httpUploadProgress", (progress) => {
                        
                        const progressVal = Math.round((progress.loaded / progress.total) * 100)
                        setProgress(progressVal);
                        console.log(progressVal);
                        
                    });
                    parallelUploads3.done().then(
                        (data) => {
                            UploadComplete();
                            console.log('done');
                            
                        }
                    );
                    } catch (e) {
                    console.log(e);
                    }
                    //end
                
              
            });
        }else{//if total files or folder less than 2gb
        
        files.forEach((file) => {
            if(file.webkitRelativePath==='' ){
                if(typeof file.path==="undefined"){
                    console.log('ekhane asar ktha noy')
                    zip.file(file.name, file);
                }else{
                    var val = file.path
                    if(val.includes("/")){
                        var valu = val.split('/');
                        valu.shift();
                        var npath=valu.join('/');
                        zip.file(npath, file);
                    }else{
                        console.log('ekhane asche')
                        var npath=file.path
                        console.log(npath)
                        zip.file(file.name, file); 
                    }
                    
                }
            }else{
                zip.file(file.webkitRelativePath, file);
                
            }
          
        });
        zip
          .generateAsync({type: 'blob'})
          .then(function (content) {
           //saveAs(content, "files.zip");
           //console.log('zip done');
            var readyFile = new File([content], uniqid+'.zip', {type: "application/zip;charset=utf-8"} );
            const file_path = uniqid+'/helibucket.zip' ;
            //console.log(file_path)
        try {
        const parallelUploads3 = new Upload({
            client: new S3Client({region:'us-east-2', credentials:creds }),
            params: { Bucket:'heli-bucket-instant-transfer', Key:file_path, Body:readyFile },
            leavePartsOnError: false, // optional manually handle dropped parts
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
            const progressVal = Math.round((progress.loaded / progress.total) * 100)
            setProgress(progressVal);
            //console.log(progressVal);
            if (progressVal===100){
                setUploadSec(false);
                setSuccessSec(true);
                if(yourOTP==''){
                    setLinkSec(true);
                }else{
                    shareFilesthoughEmail(gen_lnk);
                    
                }
            }
            
        });
        parallelUploads3.done();
        } catch (e) {
        //console.log(e);
        }

          }).catch((e) => console.log(e));

        }  
        setShowShareLink(gen_lnk);
    };
    const copyToBoard = () =>{
        var copyText = document.getElementById("copyLnk");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        copy(copyText.value);
        setCopyBtnColor(true);
        setCopyBtnTxt('Copied');
    }
    


    return ( 
        
    <section className='' >
       
    
    <div className={ showResults ? 'd-none' : null }> 
        <div className='main-section' >
            <div className='container-fluid mb-5'>
                <div className='row'>
                    <div className='col'>
                    
                    <div className='row'>
                    <div className={ formSec ?  'd-none' : null }>
                        <div className='row'>
                                            
                        <div className='col-xxl-4 col-xl-3 col-lg-3 col-md-2'></div>
                        <div className='col-sm col-lg-4 col-xxl-4 col-xl-6 col-lg-6 col-md-8 spacer-10'>
                            <div className='bg-white rounded-5 bg-file-tsfr p-3' >
                            
                            <div className='p-2'>
                                <div className={ showLink ? 'd-none' : 'null' }>
                                    
                                    <div className='text-center'>
                                       <input type='text' onChange={handleTitleIp} className='title-input' value={title} placeholder='Title*' /> 
                                    </div>
                                    <div className={boxLoader ? null : 'd-none'}>
                                        <div className='text-center mt-3  border-box-drag rounded-2'>
                                            <img src={boxLoaderPic} className='box-loader'/>
                                            
                                        </div>
                                    </div>
                                    <div className={boxLoader ? 'd-none' : null}>
                                    <div className='text-center mt-3 border-box-drag rounded-2'>
                                        <Dropzone onDrop={acceptedFiles => handleDrop(acceptedFiles)}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                
                                                    <div>
                                                        <i className="bi bi-folder-plus text-center fs-2 folder-ico mb-2"></i>
                                                        <p className='text-center border-box-drag-text'>Drag your document, photos or videos to start uploading.</p>
                                                    </div>
                                                    
                                                    <div>
                                                        <p className='border-box-drag-text'>----------  OR  ----------</p>
                                                    </div>
                                            </div>
                                            </section>
                                        )}
                                        </Dropzone>
                                        <div className='mt-3 mb-3'>
                                            <button className='ms-3 me-1 mybtn-upload' id='folderopen' onClick={() => Open('file') } >Browse File</button><button className='ms-1 me-3 mybtn-upload' id='folderopen' onClick={() => Open('folder') }>Browse folder</button>
                                        </div>
                                        <input id='folder' type="file"  onChange={handleFolderInput} style={{ display: 'none' }} webkitdirectory='' mozdirectory='true' directory='true' />
                                        <input id='file' type="file" onChange={handleFolderInput} multiple style={{ display: 'none' }}/>
                                    </div>
                                    <div className='text-end pe-3'><button disabled='true' className='show-size-btn'>{size_file_array} {Unit_total} / 5 GB</button></div>
                                    </div>
                                    
                                    <div className={ showList ? null : 'd-none' }>
                                        <div className="overflow-auto mt-1 scroll-div" >
                                            <div className='file-card' id='file-card'></div>
                                        </div>
                                    </div>
                                    <div className={ emailTransferField ? 'd-none' : null }>
                                        <div className='text-center mt-3 mb-2'>
                                            <textarea className='description-input rounded-2' onChange={handleDescription} value={description} placeholder='Description'></textarea>
                                        </div>
                                        <div className='text-center'>
                                            <button className='mybtn-upload-a me-2 btn-round-left' onClick={emailTransferBtn}><i className="bi bi-envelope"></i> Email Transfer</button>
                                            <button className='mybtn-upload-a btn-round-right' onClick={() => upload(selectedFile)} disabled={!selectedFile}><i className="bi bi-link-45deg"></i> Get a Link</button>
                                        </div>
                                    </div>

                                    <div className={ emailTransferField ?  null : 'd-none' }>
                                        <div className='mt-3'>
                                            <input type='email' id='your-email-box' className='title-file input-box bg-fafafa width-100 mb-3 p-1' onChange={yourEmailInput} placeholder="Your Email Id" required/>
                                            <input type='email' id='to-email-box' onChange={receiverEmailInput}  className='mb-3 bg-fafafa title-file input-box width-100 mb-3 p-1' placeholder="Receiver Email Id" required/>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <textarea className='description-input rounded-2 bg-fafafa' onChange={handleDescription} value={description} placeholder='Description'></textarea>
                                                </div>
                                                <div className='col-4'>
                                                <button className='me-2 rounded-pill verify-btn-a' onClick={sendVerificationMail} disabled={!selectedFile} > Verify</button>
                                                </div>

                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                
                                
                                
                                
                            </div>
                            

                            </div>
                        </div>
                        
                        <div className='col-xxl-4 col-xl-3 col-lg-3 col-md-2'></div>
                        </div>
                        </div>
                    </div>
                    <div className={ formSec ?  null : 'd-none' }>
                    <div className='row'>

                        <div className='col-lg-5 col-sm'>

                            <div className={ OTPField ?  null : 'd-none' }>
                                <div className='spacer-10'></div>
                                <div className='bg-white  p-3 card-otp-success-upload rounded-2'>
                                    <div className='text-center'>
                                        <img src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1669272808/OTP_vhxl5c.gif' className='padlock-img mb-3 mt-2' />
                                        <div >
                                        <p className='otpField-head'>Please enter the One-Time Password to verify e-mail</p>
                                        <p className='mt-3 otpField-text'>Just one quick check to make sure you’re really you. We’ve sent a<br/>verification code to <span style={{'color':'#2945a2'}}>{yourEmail}</span>  <br/>(remember to check your Spam).</p>
                                        <div className='mt-3 mb-3t ext-center'>
                                        <input type='text' id='otp-email-box' onChange={userOTPInput} className='title-fill rounded-2 mb-1 pb-2 pt-2' placeholder="Enter your OTP here" required/><br/>
                                        <a href='#' onClick={sendVerificationMail}>Resend OTP</a>
                                        </div>
                                        
                                        <div className={ OTPwrongText ?  null : 'd-none'}  style={{color:'red'}}>Your OTP not correct</div>
                                        <div className='text-center mb-3 mt-3'><button className='otpverify-a me-2  rounded-pill' onClick={() => verifyOTPandUpload() } disabled={!OTPinput}>Verify</button></div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className={ successSec ? null : 'd-none' } >
                                <div className='spacer-75'></div>
                                    <div className='bg-success-page-card rounded-2 pt-5  p-3 card-otp-success-upload'>
                                        <div className='getlink-btn'>
                                                
                                            <div >
                                                <div className='mb-5'>
                                                    <div className='text-center mt-3 mb-5'>
                                                    <img src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1667549238/94433-green-success-confirmation_fbkgix.gif' className='succes-check' />
                                                    </div>
                                                
                                                <p className='h2 text-center'>Successfully Uploaded</p>
                                                </div>
                                                
                                            </div>
                                            <div className={ linkSec ? null : 'd-none' } >
                                                
                                                <div className='row copyLinkSec'>
                                                    
                                                    <div className='col-1 border-right-link'>
                                                        <i className="bi bi-link-45deg text-color-link"></i>
                                                    </div>

                                                    <div className='col-8 pt-3'>
                                                        
                                                        <input type='text' id='copyLnk' className='width-100 ' readOnly value={showShareLink} />
                                                    </div>
                                                    <div className='col-3'>
                                                        <button onClick={copyToBoard}  data-bs-content="Copied" className={copyBtnColor?'copy-btn-color-green':'copy-btn-color-blue'} id='copy-btn' data-bs-toggle="popover" data-bs-placement="top"> {copyBtnTxt}</button>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <div className={ uploadSec ? null : 'd-none' }>
                                    
                                    <div className='spacer-50 mt-5'></div>
                                    <div className='bg-white rounded-2  p-5 card-otp-success-upload'>
                                        <div className='getlink-btn'>
                                            
                                            <div className='text-center'>
                                                <div className='mb-5'>
                                                <p className='h4 mb-3'>Please wait. Uploading in progress.</p>
                                                <div className='mb-5'>
                                                <img  src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1667546449/ezgif.com-gif-maker_3_gnp473.gif' className='transfer-img' />
                                                </div>
                                               
                                                <ProgressBar bgColor='#409AFF'  completed={progress}  className='mb-5'/>
                                                </div>
                                            
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                    
                            
                                </div>
                        </div>
                        <div className='col-lg-7 col-sm otp-side-text'>
                            <div className='spacer-200 '></div>
                            
                            <div className='side-text-box'>
                                <p className='side-text-h text-white'>For Instant Transfer up to 5GB</p>
                                <p className='text-white'>
                                With our instant file transfer feature, you can easily transfer up to 5 GB of data entirely without charge! For that, you don’t have to sign up anywhere. Just a quick transfer of files without any hassle!
                                </p>
                            </div>
                            <div className='side-text-box mt-3'>
                                <p className='side-text-h text-white'>For Store - Share - Collaborate</p>
                                <p className='text-white mb-5'>
                                Go and register youself to get 5GB of storage, where you can Store, share &amp; receive files and folders using our interactive dashboard. User can also collaborate/coauthor documents - edit, comment, negotiate, eSign, and receive.</p>
                                <a href='foldersfiles.com' className='rounded-pill shadow-sm  sign-up-side-text-btn  mt-3 pt-2 pb-2'> Explore Folders files</a>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    

   
    {/* <button style={{ display: 'none' }} type="button" id='details-btn' className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
    Launch static backdrop modal
  </button>
   */}

  <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">Share details</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <div>
          <p>Share to : {receiverEmail}</p>
          <p>Shared from: {yourEmail}</p>
          <p>Sharing data size: {size_file_array} {Unit_total}</p>
          </div>
        </div>
        
      </div>
    </div>
  </div>

  <div className="modal fade" id="exampleModalforpop" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
        <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
            <div className="text-center mb-5 mt-3">
            <p className="fs-3 text-center fw-bold"> Our Browser Version Coming Soon </p>
            <img alt='big data sharing platform helibucket' src="https://res.cloudinary.com/dgx4bwfhd/image/upload/v1667815210/New_Project_2_1_o43jrr.png" className="pc-img mt-3"></img>
            <p className="fs-5 text-center mt-3"> Download our desktop version<br/> & get 25 GB free cloud storage </p>
            <button className="btn btn-primary rounded-pill mt-3 pe-5 ps-5" onClick={()=>window.location.href='https://heliwarestorage.s3.us-west-2.amazonaws.com/bucket_data/heli_bucket/HeliBucket_Installer-0.1.exe'}>Download Now</button>
            </div>
        </div>
        
        </div>
    </div>
    </div>

  {/* <button type="button" class="btn btn-primary" id='OpenTermsPop' style={{'display':'none'}} data-bs-toggle="modal" data-bs-target="#TermsModal">
  Terms modal
  </button>
  <div class="modal fade" id="TermsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog full-width-box">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Privacy Policy</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div className='overflow-auto' style={{'height':'500px'}}>
            <TermsandConditionContent/>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Accept All</button>
      </div>
    </div>
  </div>
</div> */}


</section>
    
 );   
    
}

export default Home;