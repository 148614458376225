import React, { useState } from "react";
import "../assets/styles/landing1.css";
import bg_img from "../assets/images/mascot landing page 1.png";
import bigData from "../assets/images/Group 22.png";
import icon1 from "../assets/images/Group.svg";
import icon2 from "../assets/images/Vector.svg";
import icon3 from "../assets/images/path4625.svg";
import icon4 from "../assets/images/Icon.svg";
import icon5 from "../assets/images/Group (1).svg";
import icon6 from "../assets/images/Vector (1).svg";
import FooterNew from './FooterNew';
import HeaderNew from "./HeaderNew";
import { WebUrl } from '../helpers/constant';
import { useNavigate } from "react-router-dom";

export default function LandingPage() {
    const navigate = useNavigate();
    const getEmailFromCookie = localStorage.getItem('email');
    const tokenCookie = localStorage.getItem('token');
    const StorageType = localStorage.getItem('storageType');
    const [loader, setLoader] = useState(false);

    const openUploadPage = () => { navigate("/upload/"); };

    const openBacketPage = () => {
        setLoader(true);
        if (!getEmailFromCookie) {
            navigate('/login');
        } else {
            const URL = `${WebUrl}/file-details-list/`;
            const formData = new FormData();
            formData.append('email', getEmailFromCookie);
            formData.append('username', getEmailFromCookie); // Assuming you want to set username to the email value
            formData.append('login_token', tokenCookie);
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            fetch(URL, requestOptions)
                .then(response => response.json())
                .then(data1 => {
                    console.log("====>", data1);
                    localStorage.setItem('email', getEmailFromCookie);
                    if (data1['msg'] === "You have not any Bucket") {
                        navigate('/login');
                        setLoader(false);
                    } else {
                        StorageType === "premium" ? navigate('/dashboard-premium') : navigate('/dashboard');
                        setLoader(false);
                    }
                });
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        localStorage.removeItem("storageType");
        navigate("/login");
    };

    return (
        <div>
            <HeaderNew />
            <div className="container">
                <div className="row">
                    <div className="col text-center" style={{ marginTop: "68px" }}>
                        <div className="boxfirst">
                            <div className="borderDot">
                                <i className="bi bi-cloud-arrow-up-fill boot-cloud"></i>
                                <p style={{ fontSize: "12px", color: "grey", marginBottom: "15px" }}>Send Your File Or Folder Instantly</p>
                                <div className="upload-box" onClick={openUploadPage} style={{ color: "white" }}>
                                    <i className="bi bi-folder-symlink-fill boot-folder"></i>&nbsp;&nbsp;<span>Send Now</span>
                                </div>
                                <div className="info-box mt-4">
                                    <div style={{ display: "flex", justifyContent: "center", color: "gray", textAlign: "center", alignItems: "center" }}>
                                        <div className="res-wrd">Transfer file without sign in</div>&nbsp;&nbsp;
                                        <div className="circle1"></div>&nbsp;&nbsp;
                                        <div className="res-wrd">Send up to 5 GB</div>&nbsp;&nbsp;
                                        <div className="circle1"></div>&nbsp;&nbsp;
                                        <div className="res-wrd">Send via direct link or email</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p style={{ margin: "25px" }} className="res-mob">Maximize productivity and streamline workflows with our advanced cloud sharing platform. Effortlessly share, collaborate, and receive data - all within a single, intuitive interface. Elevate your efficiency and drive success with seamless data management.</p>
                        <button className="loginbtn1" onClick={openBacketPage}><i className="bi bi-grid-1x2-fill"></i>&nbsp;&nbsp;
                            <span>Access Your Bucket Dashboard</span><span className={`spinner-border spinner-border-sm ${loader ? null : 'd-none'} `} role="status" aria-hidden="true"></span>
                        </button>
                        <div style={{ display: "flex", justifyContent: "center", color: "gray", textAlign: "center", alignItems: "center", marginTop:"15px" }}>
                            <div className="res-wrd1">Seamless Sharing</div>&nbsp;&nbsp;
                            <div className="circle1"></div>&nbsp;&nbsp;
                            <div className="res-wrd1">Collaboration</div>&nbsp;&nbsp;
                            <div className="circle1"></div>&nbsp;&nbsp;
                            <div className="res-wrd1">Effortless Data Reception</div>
                        </div>
                    </div>
                    <div className="col text-center d-none d-lg-block">
                        <img src={bigData} className="bgImageRight img-fluid" alt="bigData" />
                        <img src={bg_img} className="bgImageRight1 img-fluid" alt="bg_img" />
                    </div>
                </div>
            </div>
            <div className="container">
    <div className="row mt-4 text-md-start text-start res-rw">
        <div className="col-12 col-md-4 col-lg-2 mb-3">
            <div className="d-flex align-items-center justify-content-start">
                <img src={icon2} alt="icon2" width={20} height={20} />&nbsp;&nbsp;
                <span className="text-black">Data Security</span>
            </div>
        </div>
        <div className="col-12 col-md-4 col-lg-2 mb-3">
            <div className="d-flex align-items-center justify-content-start">
                <img src={icon1} alt="icon1" width={20} height={20} />&nbsp;&nbsp;
                <span className="text-black">Centralize Big Data</span>
            </div>
        </div>
        <div className="col-12 col-md-4 col-lg-2 mb-3">
            <div className="d-flex align-items-center justify-content-start">
                <img src={icon5} alt="icon5" width={20} height={20} />&nbsp;&nbsp;
                <span className="text-black">Effortless Accessibility</span>
            </div>
        </div>
        <div className="col-12 col-md-4 col-lg-2 mb-3">
            <div className="d-flex align-items-center justify-content-start">
                <img src={icon6} alt="icon6" width={20} height={20} />&nbsp;&nbsp;
                <span className="text-black">Streamline Collaboration</span>
            </div>
        </div>
        <div className="col-12 col-md-4 col-lg-2 mb-3">
            <div className="d-flex align-items-center justify-content-start">
                <img src={icon3} alt="icon3" width={20.5} height={20.5} />&nbsp;&nbsp;
                <span className="text-black">Lightning-Fast Transfers</span>
            </div>
        </div>
        <div className="col-12 col-md-4 col-lg-2 ">
            <div className="d-flex align-items-center justify-content-start">
                <img src={icon4} alt="icon4" width={20} height={20} />&nbsp;&nbsp;
                <span className="text-black">Interactive User Interface</span>
            </div>
        </div>
        
        
    </div>
</div>

            <FooterNew />
        </div>
    );
}
