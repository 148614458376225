import React, { useState, useEffect, useCallback } from 'react'
import AWS from 'aws-sdk'
import {
  useParams, useNavigate
  
} from 'react-router-dom';
import { saveAs } from 'file-saver';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { WebUrl } from '../helpers/constant';
AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_KEYID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  region: process.env.REACT_APP_S3_REGION,
});
const s3 = new AWS.S3();

export default function DownloadPage() {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     //setCount('Timeout called!');
  //     //alert('jfsslkdghjdfhg');
  //     setFullPageLoader(true);
      
  //   }, 2000);
  //   return () => clearTimeout(timer);
  // }, []);
  var FileSaver = require('file-saver');
  const navigate = useNavigate();
  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);
  
  const particlesLoaded = useCallback(async container => {
    await console.log(container);
  }, []);

 const [showReadybtn, setShowReadybtn] = React.useState(false);
 const [showLoader, setShowLoader] = React.useState(true);
 const [fullPageLoader, setFullPageLoader] = React.useState(false);
 const [listFiles, setListFiles] = useState([]);
 const [listmainFiles, setListmainFiles] = useState([]);
 const [downloadBtnTxt, setdownloadBtnTxt ] = React.useState('Download Files');
 const [downloadBtnDisabled, setDownloadBtnDisabled] = React.useState(false);
 const [DLink, setDLink ] = React.useState(null);
 const dataLink = useParams();
//  const requestOptions = {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ uid: dataLink })
//       };
//       fetch(`${WebUrl}/file-details', requestOptions)
//           .then(response => response.json())
//           .then(data => setDLink(data['url']));

  const params = {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Delimiter: '',
    Prefix: dataLink['keyId']+'.zip',
  };
  //console.log(dataLink['keyId'])
  //const DLink =  'https://heliware-admin.s3.us-west-2.amazonaws.com/wetransfer/'+dataLink['keyId']+'.zip';
  //console.log(DLink)
  const DownloadFolder = (e) => {
    setdownloadBtnTxt('Please wait....');
    setDownloadBtnDisabled(true);
    setShowLoader(false);
    console.log(DLink);
    const djLink = `${WebUrl}/file_media`+dataLink+'.zip'
    if(DLink==='folder'){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ files: dataLink })
        };
        fetch(`${WebUrl}/create-zip`, requestOptions) 
            .then(response => response.json())
            .then(data => redirectTo(data['path']));
    }else{
      redirectTo(DLink);
    }
    
  }
  const redirectTo = (link) =>{
    //saveAs(link, "helibucket.zip");
    window.location.href=link;
    const myTimeout = setTimeout(myGreeting, 4000);
    function myGreeting(){
      setShowLoader(true);
      setdownloadBtnTxt('Download Again');
      setDownloadBtnDisabled(false);
    }
    

    
  }
  // const FilesNameEdit = (files) =>{
  //   var arr = [];
  //   for(var i = 0; i < files.length; i++){
  //     var file = files[i]['Key'].split("/");
  //     if (!file[2].includes('.')){
  //       var name = file[2]+'/';
  //       if(!arr.includes(name)){
  //         arr.push(name);
  //       }
  //     }else{
  //       if(!arr.includes(name)){
  //         arr.push(file[2]);
  //       }
  //     }
      
  //     setListmainFiles(arr);
  //     console.log(arr); 
  //   }

  // }
  

  useEffect(() => {
    //setFullPageLoader(true);
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ uid: dataLink })
      };
      fetch(`${WebUrl}/file-details`, requestOptions)
          .then(response => response.json())
          .then(data => {
            if(data["msg"]!=="Empty Folder"){
              setDLink(data['url']); 
              setFullPageLoader(true);
            }else{
              alert('Your link has been expired!');
              navigate('/upload');
            }
          });
  }, []);
  
  return (
    <div>
      <div className={fullPageLoader ? 'd-none' : null}>
      <div className='page-loader'>
        <div className='loader-pic-div text-center'>
            <img className='loader-gif' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1670214975/animation_500_lbaav4zb_syqdkd.gif' />
        </div>
      </div>
      </div>
      <div className={fullPageLoader ? null : 'd-none'}>
        <div className='main-section' >
        <Particles
              id="tsparticles"
              init={particlesInit}
              loaded={particlesLoaded}
              options={{
                  background: {
                      color: {
                          value: "transparent",
                      },
                  },
                  fpsLimit: 120,
                  interactivity: {
                      events: {
                          onClick: {
                              enable: true,
                              mode: "push",
                          },
                          onHover: {
                              enable: true,
                              mode: "repulse",
                          },
                          resize: true,
                      },
                      modes: {
                          push: {
                              quantity: 10,
                          },
                          repulse: {
                              distance: 200,
                              duration: 0.4,
                          },
                      },
                  },
                  particles: {
                      color: {
                          value: "#ffffff",
                      },
                      
                      links: {
                          color: "#ffffff",
                          distance: 150,
                          enable: true,
                          opacity: 0,
                          width: 1,
                      },
                      collisions: {
                          enable: true,
                      },
                      move: {
                          directions: "none",
                          enable: true,
                          outModes: {
                              default: "bounce",
                          },
                          random: false,
                          speed: 2,
                          straight: false,
                      },
                      number: {
                          density: {
                              enable: true,
                              area: 800,
                          },
                          value: 80,
                      },
                      opacity: {
                          value: 0.2,
                      },
                      shape: {
                          type: "circle",
                      },
                      size: {
                          value: { min: 1, max: 5 },
                      },
                  },
                  detectRetina: true,
              }}
          />
        <div className='container-fluid my-mobile-menu'>
            <div className='row navbar-sec'>
                <div className='col-lg-9 col-6 col-sm col-md '><a href='/' ><img alt='helibucket Large File Transfer Up to 5GB' className='logo-img mobile-logo' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1669694954/logo.f41121c2c999ba320309_1_b1qmjy-min_hg6uo9.png' /></a></div>
                <div className='col-lg-3 col-6 col-sm col-md '>
                    <div className='text-end'>
                    <button className="btn-invisible-bg my-mobile-menu " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                        <i className="bi bi-list text-white bar-ico-menu"></i>
                    </button>
                    </div>
                
                </div>
            </div>
        </div>
        <div className="offcanvas offcanvas-end download-menu-page" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
              <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasExampleLabel"><a href='#' ><img alt='file share, send large files free throught helibucket' className='logo-img-mob-menu' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1669694954/logo.f41121c2c999ba320309_1_b1qmjy-min_hg6uo9.png' /></a></h5>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                  <div>
                          <ul className="dropdown-menu-v">
                              <li><a href='/' className="dropdown-item" onClick={()=>navigate("/")} >Home</a></li>
                              <li><a href='/upload/' className="dropdown-item mt-3" onClick={()=>navigate("/upload/")} >Upload</a></li>
                              <li><a href='/about/' className="dropdown-item mt-3" onClick={()=>navigate("/about/")} >About</a></li>
                              <li><a href='/contact/' className="dropdown-item mt-3 " onClick={()=>navigate("/contact/")} >Contact Us</a></li>
                              <li><a href='/register' className="dropdown-item mt-3 "  onClick={()=>navigate("/register")}>Sign Up</a></li>
                              <li><a href='/login' className="dropdown-item mt-3 " onClick={()=>navigate("/login")} data-bs-toggle="modal" data-bs-target="#exampleModalforpop" >Sign In</a></li>
                          </ul>
                  </div>
                  <div className='mt-5 text-center'>
                      <button className='rounded-pill btn-mob-menu'>Download Desktop Version</button>
                  </div>
                  <div className='mt-5 text-center'>
                      <div className='mob-menu-social text-center'>
                          <div className='me-3'>
                          <a className='me-3 color-aaa' href='https://www.facebook.com/HeliBucket'><i className="bi bi-facebook fs-3"></i></a>
                          <a className='me-3 color-aaa' href='https://instagram.com/heli_bucket'><i className="bi bi-instagram fs-3"></i></a>
                          <a className='me-3 color-aaa' href='https://www.linkedin.com/showcase/helibucket/'><i className="bi bi-linkedin fs-3"></i></a>
                          <a className='me-3 color-aaa' href='https://twitter.com/heli_bucket'><i className="bi bi-twitter fs-3"></i></a>
                          <a className='me-3 color-aaa' href='https://youtube.com/channel/UCykpS0MUzwhdcmRmWpY2uLQ'><i className="bi bi-youtube fs-3"></i></a>
                          </div>
                        
                      </div>
                  </div>
                  
                  
              </div>
          </div>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 col-xl-5 col-xxl-5'>
              <a href='/' ><img alt='file share website, send large files' className='logo-img downoad-page-logo' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1669694954/logo.f41121c2c999ba320309_1_b1qmjy-min_hg6uo9.png' /></a>
                <div className='spacer-75-for-d-pg'></div>
                <div className='mt-5'>
                  <p className='text-white download-page-left-text'>Welcome to helibucket</p>
                  <div className='mt-3 d-btn-outer-div'>
                    <button className='rounded-pill btn-download' onClick={DownloadFolder} disabled={downloadBtnDisabled} >{downloadBtnTxt} <span className={showLoader ? null: 'd-none'}><i className="bi bi-download"></i></span><span className={showLoader ? 'd-none': null}><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span></button>
                  </div>
                  <div className='spacer-10-for-d-pg'></div>
                  <div>
                    <p className='first-text-d-page'>Send your file instantly</p>
                    <div className='mt-3'>
                      <p className='list-text-d-page'><i className="bi bi-circle-fill bullet-d-page"></i> Send upto 5GB data.</p>
                      <p className='list-text-d-page'><i className="bi bi-circle-fill bullet-d-page"></i> Instant and speedy transfer via link or email.</p>
                      <p className='list-text-d-page'><i className="bi bi-circle-fill bullet-d-page"></i> No need to Sign In or Sign Up for transfering data.</p>
                    </div>
                    <div className='mt-3'>
                      <button className='send-now-btn-d-page' onClick={()=>navigate("/upload/")}>Send Now</button>
                    </div>
                  </div>
                  <div className='spacer-10'></div>
                  
                </div>
              </div>
              <div className='col-md-12 col-xl-7 col-xxl-7 download-page-right-side'>
                <div className='download-page-right-side-img'>
                      <ul className="nav justify-content-end pt-3 pe-5">
                          <li className="nav-item">
                              <button className="nav-link nav-link-c" aria-current="page" onClick={()=>navigate("/upload/")} >Upload</button>
                          </li>
                          <li className="nav-item">
                              <button className="nav-link nav-link-c" onClick={()=>navigate("/about")} >About Us</button>
                          </li>
                          <li className="nav-item">
                              <button className="nav-link nav-link-c" onClick={()=>navigate("/contact")} >Contact Us</button>
                          </li>
                          <li className="nav-item">
                              <button className="nav-link nav-link-c"  onClick={()=>navigate("/register")}>Sign Up</button>
                          </li>
                          <li className="nav-item">
                              <button className="nav-link nav-link-c" onClick={()=>navigate("/login")} data-bs-toggle="modal" data-bs-target="#exampleModalforpop" >Sign In</button>
                          </li>
                      </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="exampleModalforpop" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
        <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
            <div className="text-center mb-5 mt-3">
            <p className="fs-3 text-center fw-bold"> Our Browser Version Coming Soon </p>
            <img alt='big data sharing platform helibucket' src="https://res.cloudinary.com/dgx4bwfhd/image/upload/v1667815210/New_Project_2_1_o43jrr.png" className="pc-img mt-3"></img>
            <p className="fs-5 text-center mt-3"> Download our desktop version<br/> & get 25 GB free cloud storage </p>
            <button className="btn btn-primary rounded-pill mt-3 pe-5 ps-5" onClick={()=>window.location.href='https://heliwarestorage.s3.us-west-2.amazonaws.com/bucket_data/heli_bucket/HeliBucket_Installer-0.1.exe'}>Download Now</button>
            </div>
        </div>
        
        </div>
    </div>
    </div>
    </div>
      
      
    
    
  )
}
