import React, {useState, useEffect} from 'react';
import '../assets/styles/profile.css'
import swal from 'sweetalert';

export default function Verification() {
    const [fullPageLoader, setFullPageLoader] = React.useState(false);
    const [BtnLoader, setBtnLoader] = React.useState(true);
    const [Fullname, setFullname] = useState('');
    const [Address, setAddress] = useState('');
    const [Country, setCountry] = useState('');
    const [Email, setEmail] = useState('');
    const [PersonalEmail, setPersonalEmail] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Purpose, setPurpose] = useState('');
    const handleFullname = (e) =>{setFullname(e.target.value)}
    const handleAddress = (e) =>{setAddress(e.target.value)}
    const handleCountry = (e) =>{setCountry(e.target.value)}
    const handleEmail = (e) =>{setEmail(e.target.value)}
    const handlePersonalEmail = (e) =>{setPersonalEmail(e.target.value)}
    const handleMobile = (e) =>{setMobile(e.target.value)}
    const handlePurpore = (e) =>{setPurpose(e.target.value)}

    const Handlesubmit = () =>{

        setBtnLoader(false);
        if(Purpose.length<901){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ bucket_email: Email, email:PersonalEmail, name: Fullname, address: Address, country: Country, contact_no:Mobile, bucket_use:Purpose })
                };
                fetch('https://helibucket.heliware.co.in/heli_mount/user_subinfo_api/', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        setBtnLoader(true);
                        if(data['message']==='successfully filled description form'){
                            swal("Good job!", "Successfully Submit! We will contact you soon..", "success");
                        }else if(data['message']==='already filled description form'){
                            swal("Ohhh!", "Already filled this form!", "warning");
                        }else{
                            swal("Sorry!", "Your data not correct!", "error");
                        }
                    });
        }
        
    }
useEffect(() => {
    setFullPageLoader(false);
    const timer = setTimeout(() => {
      //setCount('Timeout called!');
      //alert('jfsslkdghjdfhg');
      setFullPageLoader(true);
      
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className='verify-page'>
        <div className={fullPageLoader ? 'd-none' : null}>
      <div className='page-loader'>
        <div className='loader-pic-div text-center'>
            <img className='loader-gif' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1670214975/animation_500_lbaav4zb_syqdkd.gif' />
        </div>
      </div>
      </div>
        <div className='container'>
            <div className='row'><img className='mt-3' style={{'width':'170px', 'height':'auto'}} src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1669208333/logo_1_ny5kuf_1_hcguue.png'/></div>
            <div className='row'>
                <p className='h1 text-center bold mt-3'>Verify yourself as helibucket user</p>
                <div className='col-sm-12 col-lg-4'></div>
                <div className='mt-3 col-lg-4 col-sm-12 text-center'>
                    <div className='text-center'>
                        <input className='verifyinput form-control mb-3' type='text' placeholder='Fullname' onChange={handleFullname} value={Fullname}  />
                        <input className='verifyinput form-control mb-3' type='text' placeholder='Address' onChange={handleAddress} value={Address} />
                        <input className='verifyinput form-control mb-3' type='text' placeholder='Country' onChange={handleCountry} value={Country}  />
                        <input className='verifyinput form-control mb-3' type='email' placeholder='Email Address use in helibucket' onChange={handleEmail} value={Email} />
                        <input className='verifyinput form-control mb-3' type='email' placeholder='Email Address (Personal)' onChange={handlePersonalEmail} value={PersonalEmail} />

                        <input className='verifyinput form-control mb-3' type='text' placeholder='Mobile No with country code' onChange={handleMobile} value={Mobile} />
                        
                        <textarea className='verifyinput form-control mb-3' placeholder='Purpose of using helibucket (Max 900 Characters)' onChange={handlePurpore} value={Purpose} style={{'height':'150px'}}></textarea>
                        
                        <div className='text-center'>
                            <button className='btn btn-primary' onClick={Handlesubmit}>
                                Complete Validation
                                <span className={BtnLoader?'d-none':null}><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>
                                
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4'></div>
            </div>
            
            
        </div>
    </div>
  )
}
