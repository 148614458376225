import React from 'react'
import collaborationImage from '../assets/images/collaboration.svg';
import loader_new from '../assets/images/loader_new.gif';
export default function Info_commenting({FileInfo,collaboration,CollaboerationLoader , clearCollaborationState}) {
  const sizeCalculation =(v) =>{
    if(v<1024 ){
        // v = parseFloat(v/1024).toFixed(2);
        return (v+" bytes")
    }
    else if(v>1024 && v<1024*1024){
        v = parseFloat(v/1024).toFixed(2);
        return (v+" KB")
    }else if(v>(1024*1024) && v<1024*1024*1024){
        v = parseFloat(v/(1024*1024)).toFixed(2) ;
        return (v+" MB")
    }else if(v>(1024*1024*1024)){
        v = parseFloat(v/(1024*1024*1024)).toFixed(2) ;
        return (v+" GB")
    }
}
const extractLastParts =(filenames) =>{
  const parts = filenames.split('/');
  const lastPart = parts[parts.length - 1];
  return lastPart;
  
}
  return (
    <div>
        <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex={-1} id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel" >
        <div className="offcanvas-header " style={{'marginTop':'-40px' , 'right':'0'}}>
              <button type="button" onClick={clearCollaborationState} className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
          <div className= {`${CollaboerationLoader ? "d-flex position-absolute top-50 start-50 translate-middle":"d-none"}`}>
            <img className='file-icon-list ' style={{"width":"80px" , "height":"auto"}} src={loader_new} />
          </div>
          <div className={`${CollaboerationLoader ? "d-none":null}`} >
            
            <div className="offcanvas-body ms-4">
                <h5 className="offcanvas-title mb-2" id="offcanvasScrollingLabel">
                  <span><i class="bi bi-info-circle"></i> </span>File info        
                </h5>
                <div className='ps-4'>
                    <h6>File Name :{FileInfo.map((info,index) => <span key={index}>{extractLastParts(info.fileName)}</span>)}</h6>
                    <h6>Size : {FileInfo.map((info,index) => <span key={index}>{sizeCalculation(info.size)}</span>)}</h6>
                    <h6>Filepath : {FileInfo.map((info,index) => <span key={index}>{info.fileName}</span>)} </h6>
                  </div>

                <div className='pb-5' >
                  <h5 className="offcanvas-title ms-1" id="offcanvasScrollingLabel">
                    <span><img style={{'height':"20px","width":"auto"}} src={collaborationImage}/> </span>Collaboration       
                  </h5>
                  <div className='collaboration'>
                  <div class="email_tag_div" data-aos="fade-up">
                      <div class="email__tag__list">
                          {collaboration.length === 0 ? (
                            <ul>
                              <li>
                              <p>File not shared with anyone.</p>
                            </li>
                            </ul>
                          ) : (
                            <ul>
                              {collaboration.map((item, index) => {
                                return <li key={index}>
                                  <a href="#">{item}</a>
                                </li>
                                })}
                            </ul>
                          )}
                          
                      </div>

                      </div>
                  </div>
                </div>
                {/* <div  className='bg-light p-3 rounded' >
                <h5 className="offcanvas-title ms-1" id="offcanvasScrollingLabel">
                    <button  className='bg-transparent mb-2'>
                      <span><i class="bi bi-chat-right"></i> </span>Commenting 
                    </button>
                    <section>
                      <div className="container py-1">
                        <div className="row d-flex justify-content-center">
                          <div className="col-12">
                              <div className="card-body">
                                <div className="d-flex flex-row justify-content-start mb-4">
                                <div >
                                    <i style={{ width: "30px", height: "100%" }} class="bi bi-person-circle"></i>
                                  </div>
                                  <div
                                    className="p-3 ms-3"
                                    style={{
                                      borderRadius: 15,
                                      backgroundColor: "rgba(57, 192, 237,.2)"
                                    }}
                                  >
                                    <p className="small mb-0">
                                      <h6 className='text-success'><b>sourav@heliware.co.in</b></h6>
                                      Hello and thank you for visiting helibucket. 
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-row justify-content-end mb-4">
                                  <div
                                    className="p-3 me-3 border"
                                    style={{ borderRadius: 15, backgroundColor: "#fbfbfb" }}
                                  >
                                    <p className="small mb-0">
                                      Thank you, I really like your product.
                                    </p>
                                  </div>
                                  <div>
                                    <i style={{ width: "30px", height: "100%" }} class="bi bi-person-circle"></i>
                                  </div>
                                </div>

                                <div className="form-outline">
                                  <textarea className="form-control" id="textAreaExample" rows={1} style={{"borderRadius":"15px"}}/>
                                  <div  className='container'>
                                  <button className='btn btn-dark mt-2 align-content-end d-flex float-end'>send</button>
                                  </div>
                                  
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </section>  
                </h5>
                </div> */}
            </div>
          </div>
       
        </div>

    </div>
  )
}
