import React, {useState, useEffect ,useRef} from 'react'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import deleteIcon from '../assets/images/Bin.json'
import Lottie from 'react-lottie-player'
import CommentSection from './CommentSection';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Sidebar from './Sidebar';
import ProgressBar from './ProgressBar';
import SearchIco from '../assets/images/SearchIcon.svg';
import crossIcon from '../assets/images/cross.svg';
import FolderIcon from '../assets/images/folder-icon.svg'
import FileIcon from '../assets/images/file-icon.svg'
import ModalShare from './ModalShare';
import loader_new from '../assets/images/loader_new.gif';
import Loader from '../assets/images/loader.gif';
import Loader1 from '../assets/images/loading.gif';
import DragDrop from '../assets/images/DragDrop.gif';
import not_found from '../assets/images/not_found.gif';
import {useNavigate} from "react-router-dom";
import Swal from 'sweetalert2';
import { useDropzone } from "react-dropzone";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Slider from "react-slick";
import createFolder from "../assets/images/create_folder.png"
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";
import InfoAndComment from './Info_commenting';
import {WebUrl} from '../helpers/constant';
// const WebUrl  =  process.env.REACT_APP_URLS 
const S3_BUCKET ='heli-bucket-storage';
const REGION ='us-west-2';
const creds = {
    accessKeyId: process.env.REACT_APP_S3_KEYID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    
}

export default function PremimumDashboard() {
const getEmailFromCookie = localStorage.getItem('email');
const getNameFromCookie = localStorage.getItem('name');
const tokenCookie = localStorage.getItem('token');
const [sidebar, setSidebar] = useState('300px');
const [filesSec, setfilesSec] = useState('300px');
const [sidebarText, setsidebarText]=useState(false);
const [FolderDatas, setFolderDatas]=useState([]);
const [FileDatas, setFileDatas]=useState([]);
const [FileSizeDatas, setFileSizeDatas]=useState([]);
const [BucketName, setBucketName]=useState("");
const [AccessCode, setAccessCode]=useState("");
const [loading, setLoading] = useState(true);
const [loading1, setLoading1] = useState(false);
const [loading2, setLoading2] = useState(false);
const [comingdata, setCommingData] = useState(false);
const [webPath , setWebPath ]= useState("");
const [selected , setSelected] = useState(false);
const navigate = useNavigate();
const [searchValue , setSearchvalue] = useState("");
const [extender , setExtender] = useState(true)
// selection
const [SelectFilesArr, setSelectFilesArr]=useState([]);
const [SelectFileLength, setSelectFileLength]=useState(0);
// de select
const [SelectFileIds, setSelectFileIds] = useState([]);
const [useSize , SetUsedSize] = useState("");
const [BucketSize , SetBucketSize] = useState("");
//others
const [files, setFiles] = useState(null);
const [uploaderImage , setuploaderImage] = useState(false);
const [bucketFolderFile , setFolderFile] = useState([])
const inputRef = useRef();
///sharing email list 
const [emailList , SetEmailList] = useState([])
// Progress and set upload file name
const [Progress ,setProgress] =useState("");
const [ProgressON , setPogressBarON] = useState(false);
const [uploadFileName , setUploadFileName] = useState("")
const S3_BUCKET ='heli-bucket-storage';
//searching 
const [searching , SetSearching ] =useState(false)
//folder or multiple file zipping
const [zipping , SetZipping] = useState(false)
//view files by extension
const [viewfile , SetViewFile] = useState("") 
const [FileViewDiv , setFileViewDiv] =useState(false)
const [FileExtension , SetFileExtension] = useState("")
const[fileType , SetFileType] = useState("")
const [fileName, setFileName] =useState("")
///view File collaboration details
const [FileInfo , setFileinfo] = useState([])
const [FileCollaborationData , setFileCollaborationData] = useState([])
const [CollaboerationLoader  , setCollaboerationLoader] = useState(false)
//check folder or File
const [CheckFileOrFolder , SetCheckFolderOrFile] = useState("")
const [uploadFileLength  , setUploadFilelength] = useState(0)
const [presentlyUploaded, setpresentlyUploaded] = useState(0);
//save tiff file data 
const [TiffFileDownloadData , SetTiffFileDownloadData] = useState("")
const [FolderDelete ,SetFolderDelete ] = useState(false)
const [ShowFile  , setShowFile] = useState(false)
const [commentsinfo , setCommentinfo] = useState([])
//email don't have for share then this state work 
const [chatavilable , setChatAvailable] =useState(false)

const user_effect =() =>{
    //let width = screen.width;

    let width = window.innerWidth;
    if(width<932){
        openSideBar()
        setExtender(false)
        
    }
    if (getEmailFromCookie === null){
        navigate('/login/');
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: getEmailFromCookie })
    };
    const formData = new FormData();
        formData.append('email', getEmailFromCookie);
        formData.append('username', getEmailFromCookie); // Assuming you want to set username to the email value
        formData.append('login_token', tokenCookie);
    const requestOptions1 = {
        method: 'POST',
        // headers: { 'Content-Type': 'application/json' },
        body:formData //JSON.stringify({ email: getEmailFromCookie })
    };
    fetch(`${WebUrl}/previous-email-share-list/` , requestOptions)
    .then(response => response.json())
    .then(data=>{
        SetEmailList(data['email_list'])
    })
    fetch(`${WebUrl}/trash-file-list-with-decoretor/`, requestOptions1)
        .then(response => response.json())
        .then(data => {
            console.log('=====>',data);
            if(data["msg"]==="you are not authentic user"){
                handleLogout()
                navigate('/login')
            }
            else{
                setBucketName(data['bucket']);
                setAccessCode(data['access_code']);
                setWebPath(data['bucket']+"/");
                SetUsedSize(data["used_size"])
                SetBucketSize(data["total_size"])
                const temp_arr_folder=[];
                const temp_arr_files=[];
                const totalFolderFile =[];
                // const temp_arr_file_size =[];
                for(let i=0; i<data['data'].length; i++){
                    let datas = data['data'][i][0];
                    let data_s = data['data'][i][1];
                    totalFolderFile.push([datas]);
                    setFolderFile(totalFolderFile)
                    let fileSize = data_s
                    // var data_s =sizeCalculation(data_z);
                    if(datas.indexOf(".")===-1){
                        temp_arr_folder.push(datas);
                    }
                    else{
                        temp_arr_files.push([datas,fileSize]);
                    }
                }
                setFolderDatas(temp_arr_folder);
                setFileDatas(temp_arr_files);
                // setFileSizeDatas(temp_arr_file_size);
                setCommingData(true);
                setLoading(false);
                console.log("webpath: ",data['bucket']+"/");

            }
           

    });
    // console.log(import.meta.env.REACT_APP_S3_KEYID)
    
}
const extractLastParts =(filenames) =>{
    const parts = filenames.split('/');
    
    const lastPart = parts[parts.length - 1];
    if (lastPart.includes("@Trash")){
      const mainPart = lastPart.split("@Trash")[0]
      return mainPart

    }
    return lastPart;
}
const responsive = {
    desktop: {
      breakpoint: { max: 1920, min: 1190 },
      items: 3
    },
    laptop:{
        breakpoint: { max: 1189, min: 768 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

const userEffectWithBase =(webpath) =>{
    setLoading(true)
    const regex = /\//g; // Regular expression to match forward slashes
    const matches = webPath.match(regex);
    if (getEmailFromCookie === null){
        navigate('/login');
    }
    const formData = new FormData();
        formData.append('email', getEmailFromCookie);
        formData.append('username', getEmailFromCookie); // Assuming you want to set username to the email value
        formData.append('login_token', tokenCookie);
        // formData.append('path', matches && matches.length >= 2 ? webpath : undefined);
    const requestOptions = {
        method: 'POST',
        headers:matches && matches.length >= 2 ? { 'Content-Type': 'application/json' }:undefined,
        body: matches && matches.length >= 2 ? JSON.stringify({ email: getEmailFromCookie , path: matches && matches.length >= 2 ? webPath : undefined  }) : formData//
    };
    fetch(matches && matches.length >= 2 ?  `${WebUrl}/trash-subfolder-list/`: `${WebUrl}/trash-file-list-with-decoretor/` , requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('=====>',data);
            setBucketName(data['bucket']);
            setAccessCode(data['access_code']);
            setWebPath(webpath);
            SetUsedSize(data["used_size"])
            SetBucketSize(data["total_size"])
            const temp_arr_folder=[];
            const temp_arr_files=[];
            const totalFolderFile =[];
            // const temp_arr_file_size =[];
            for(let i=0; i<data['data'].length; i++){
                let datas = data['data'][i][0];
                let data_s = data['data'][i][1];
                totalFolderFile.push([datas]);
                setFolderFile(totalFolderFile)
                let fileSize = data_s
                // var data_s =sizeCalculation(data_z);
                if(datas.indexOf(".")===-1){
                    temp_arr_folder.push(datas);
                }
                else{
                    temp_arr_files.push([datas,fileSize]);
                }
            }
            setFolderDatas(temp_arr_folder);
            setFileDatas(temp_arr_files);
            setSelectFilesArr([]);
            setSelectFileLength(0);
            setCommingData(true);
            setLoading(false);
            
            console.log("webpath: ",data['bucket']+"/");

    });
    // console.log(import.meta.env.REACT_APP_S3_KEYID)
    
}
//use effect start
useEffect(()=>{
    user_effect();
    console.log(WebUrl)
},[]);
//use effect end

//file draging for upload handel start
  const handleDragOver = (event) => {
    event.preventDefault();
    setuploaderImage(true)
  };
//file draging for upload handel end

//handel when file drgging leave start
const handleDragLeave =() =>{
    if (uploaderImage ) {
        // Make your request here.
        setuploaderImage(false)
      }
    
}
//handel when file drgging leave end



// here upload file to backend 
  const show_files =(files)=>{
    const elements = [];
    for (let idx = 0; idx < files.length; idx++) {
        const file = files[idx];
        elements.push(file);
      }
    console.log(elements);
    uploadfile(elements);
    return elements;
      
  }

  const uploadfile = async (acceptedFiles) =>{
    const files = acceptedFiles;
    var MyBucket = webPath;
    console.log("accepted file@@@@@@@@@@@",files);
    console.log(MyBucket); 
    let filesLength = files.length;
    let totalFileLength = 0;
    setPogressBarON(true);
    setUploadFilelength(filesLength);
    for(var i=0; i < files.length; i++){
            if (files[i].path.charAt(files[i].path.length-files[i].path.length)==='/'){
                const trimmedFolderName = MyBucket.replace(/\/$/, '');
                var fileName = trimmedFolderName+files[i].path;
                console.log("folder name is " + fileName)
            }else{
                var fileName = MyBucket+files[i].path;
                console.log("file name is " + fileName)
            }
              try {
                const parallelUploads3 =new Upload({
                    client: new S3Client({region:'us-west-2', credentials:creds }),
                    params: { Bucket:'heli-bucket-storage', Key:fileName, Body:files[i] },
                    leavePartsOnError: false, // optional manually handle dropped parts
                });
                parallelUploads3.on("httpUploadProgress", (progress) => {
                    const splitArray = fileName.split("/");
                    const FilenameWithoutPath = splitArray[splitArray.length - 1];
                    setUploadFileName(FilenameWithoutPath);
                    const progressVal =Math.round((progress.loaded / progress.total) * 100)
                    setProgress(progressVal);
                    
                });
                await parallelUploads3.done().then(
                    (data) => {
                        totalFileLength =totalFileLength +1
                        setUploadFilelength(filesLength)
                        setProgress(0);
                        setUploadFileName("");
                        if(totalFileLength == filesLength){
                            upload_in_mongo_for_drag_and_drop(acceptedFiles , MyBucket)
                            setPogressBarON(false);
                            setpresentlyUploaded(presentlyUploaded+1);
                        }
                    }
                );
                } catch (e) {
                    console.log('===>',e);
                }
    }
    


  }


  const upload_in_mongo_for_drag_and_drop =(acceptedFiles , MyBucket)  =>{
    var arrFlist=[];
    for(let i =0;i<acceptedFiles.length;i++){
      if(acceptedFiles[i].path.includes('/')){
        const trimmedFolderName = MyBucket.replace(/\/$/, '');
        console.log(trimmedFolderName);
        arrFlist.push([trimmedFolderName+acceptedFiles[i].path,acceptedFiles[i].size]);
        console.log(trimmedFolderName+acceptedFiles[i].path,"=",acceptedFiles[i].size);
      }
      else {
        arrFlist.push([MyBucket+acceptedFiles[i].path,acceptedFiles[i].size]);
        console.log(MyBucket+acceptedFiles[i].path,"=",acceptedFiles[i].size);

      }
    console.log(acceptedFiles[i])
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: getEmailFromCookie, bucket_files: arrFlist })
      };
      fetch(`${WebUrl}/upload-file/`, requestOptions)
          .then(response => response.json())
          .then(data =>{
            // setProgress(0)
            console.log('successfully updated');
            userEffectWithBase(MyBucket);
          } );
  }

const uploadInMongoDb_forInput =(acceptedFiles , MyBucket) =>{
    var arrFlist=[];
    for(let i =0;i<acceptedFiles.length;i++){
      if(acceptedFiles[i].webkitRelativePath.includes('/')){
        arrFlist.push([MyBucket+acceptedFiles[i].webkitRelativePath,acceptedFiles[i].size]);
        console.log(MyBucket+acceptedFiles[i].webkitRelativePath,"=",acceptedFiles[i].size);
      }
      else {
        arrFlist.push([MyBucket+acceptedFiles[i].name,acceptedFiles[i].size]);
        console.log(MyBucket+acceptedFiles[i].name,"=",acceptedFiles[i].size);
      }
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: getEmailFromCookie, bucket_files: arrFlist })
      };
      fetch(`${WebUrl}/upload-file/`, requestOptions)
          .then(response => response.json())
          .then(data =>{
            // setProgress(0)
            console.log('successfully updated');
            userEffectWithBase(MyBucket);
          } );
}



const uploadFileByInput = async (acceptedFiles) =>{
    setPogressBarON(true);
    const files = acceptedFiles;
    var MyBucket = webPath;
    console.log("accpted file@@@@@@@@@@@",files);
    console.log(MyBucket); 
    setPogressBarON(true)
    let filesLength = files.length;
    let totalFileLength = 0;
    setUploadFilelength(filesLength);
    for(var i=0; i < files.length; i++){ 
      if (files[i].webkitRelativePath.includes('/')){
        var fileName = MyBucket+files[i].webkitRelativePath;
        console.log("folder name is " + files[i].webkitRelativePath)
        //console.log(i);
      }else{
        var fileName = MyBucket+files[i].name;
        console.log("file name is " + files[i].name)
        //console.log(i);
      }

      try {
        const parallelUploads3 = new Upload({
            client: new S3Client({region:'us-west-2', credentials:creds }),
            params: { Bucket:'heli-bucket-storage', Key:fileName, Body:files[i] },
            leavePartsOnError: false, // optional manually handle dropped parts
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
            const splitArray = fileName.split("/");
            const FilenameWithoutPath = splitArray[splitArray.length - 1];
            setUploadFileName(FilenameWithoutPath);
            const progressVal = Math.round((progress.loaded / progress.total) * 100)
            setProgress(progressVal);
        });
        await parallelUploads3.done().then(
            (data) => {
                totalFileLength =totalFileLength +1
                setUploadFileName("");
                setProgress(0);
                setpresentlyUploaded(totalFileLength+1);
                if(totalFileLength == filesLength){
                    setpresentlyUploaded(0)
                    uploadInMongoDb_forInput(acceptedFiles , MyBucket)
                    setPogressBarON(false)
                }
            }
        );
        } catch (e) {
        console.log('===>',e);
        }
      
   }
  }




///FILE DETAILS VIEW AND Collaboration
const collaborationData =(item)=>{
    setCollaboerationLoader(true)    
    const  bashpath = webPath+item
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: getEmailFromCookie, bashpath:bashpath  })
      }
      fetch(`${WebUrl}/fie-info-and-collaboration/`, requestOptions)
          .then(response => response.json())
          .then(data =>{
            console.log(data) 
                const fileInfoEntry = { fileName: data['fileName'], size: data['size'] };
                FileInfo.push(fileInfoEntry)
                setFileCollaborationData(data['EmailList']);
                setCollaboerationLoader(false)
            } );
    

    // setFileCollaborationData
}


//collaboration state clear 
const clearCollaborationState =()=>{
    setFileinfo([])
    setFileCollaborationData([])
}
//when file drop in browser space which handel here start  
  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
    setuploaderImage(false)
    // console.log(files)
    show_files(event.dataTransfer.files);

  };

//when file drop in browser space which handel here end  
    const  search = async (e) => {
        let search_input = e.target.value;
            
                setSearchvalue(search_input)
                const suggestionbox = document.getElementById("suggestion_box");
                   let elements =  document.getElementById('searchlist');
                   // elements.style.borderRadius = '25px 25px 0 0';
                   if (search_input.length == 0){
                       suggestionbox.classList.add("d-none");
                       // elements.style.borderRadius = '25px';
                   }
                   suggestionbox.classList.remove("d-none");
                   // suggestionbox.classList.add("d-block")
      }
//calculating file sizing start
const sizeCalculation =(v) =>{
    if(v<1024 ){
        // v = parseFloat(v/1024).toFixed(2);
        return (v+" bytes")
    }
    if(v>1024 && v<1024*1024){
        v = parseFloat(v/1024).toFixed(2);
        return (v+" KB")
    }else if(v>(1024*1024) && v<1024*1024*1024){
        v = parseFloat(v/(1024*1024)).toFixed(2) ;
        return (v+" MB")
    }else if(v>(1024*1024*1024)){
        v = parseFloat(v/(1024*1024*1024)).toFixed(2) ;
        return (v+" GB")
    }
}  
//calculating file sizing end
 const allConst =() =>{
    setSelectFilesArr([]);
    setSelectFileLength(0);
 }

    const openSideBar = () =>{
        setsidebarText(true);
        setSidebar('100px');
    }
    const closeSidebar = () =>{
        setsidebarText(false);
        setSidebar('300px');
    }

//call sub foder data start
    const SubFolderCall = (web_path) =>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: getEmailFromCookie , path:web_path })
        };
        fetch(`${WebUrl}/trash-subfolder-list/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log('=====>',data);
                const temp_arr_folder=[];
                const temp_arr_files=[];
                const totalFolderFile =[];
                for(let i=0; i<data['data'].length; i++){
                    let datas = data['data'][i][0];
                    let data_s = data['data'][i][1];
                    totalFolderFile.push([datas]);
                    setFolderFile(totalFolderFile)
                    let fileSize = data_s
                    if(datas.indexOf(".")===-1){
                        temp_arr_folder.push(datas);
                    }
                    else{
                        temp_arr_files.push([datas,fileSize]);
                    }
                }
                setFolderDatas(temp_arr_folder);
                setFileDatas(temp_arr_files)
                setCommingData(true);
                setLoading(false);
                allConst()
                
        });
    }
//call sub foder data end


///get subfolder data start
    const getSubFolderdata =(item) =>{
        setLoading(true);
        const web_path = webPath +item+"/";
        setWebPath(web_path);
        SubFolderCall(web_path);
        DeSelectAll()
        console.log("webpath sub api: ",web_path);
    }
///get subfolder data end


///back from folder start
    const backSubFolder =() =>{
        setLoading(true);
        console.log(webPath)
        let pathElements = webPath.split('/');
        pathElements.pop();
        pathElements.pop();
        console.log('@@@@',pathElements );
        let url = pathElements.join('/'); // join remaining elements back together
        const web_path = url +"/";
        console.log("webpath sub api: ",web_path);
        setWebPath(web_path);
        SubFolderCall(web_path);
        allConst()
        DeSelectAll()
        
        
    
    }
///back from folder end
const DeSelectAll = () =>{
    console.log(SelectFileIds)
    if (SelectFileIds.length !== 0){
        for (const element of SelectFileIds) {
            if (CheckFileOrFolder === "folder") {
                document.getElementById(`Folder-${element}`).parentNode.style="background:background:linear-gradient(180deg, #ebe9e9 0%, #dddcdc 100%)";
                document.getElementById(`Folder-${element}`).style="background:linear-gradient(180deg, #ebe9e9 0%, #dddcdc 100%)";
                document.querySelector(`[data-bs-target="#dropdownMenuButton-${element}"]`).classList.remove('d-none');
            } else {
                document.getElementById(`file-items-${element}`).style="background-color:#FFF";
                document.getElementById(`check-${element}`).checked = false;
              }
            

          }
          document.getElementById("all_download").classList.remove("d-flex")
          document.getElementById("all_download").classList.add("d-none")
    }
    
    
}
    

///select Folder 
const SelectFolder=(filename,id)=>{
    if(!SelectFilesArr.includes(webPath+ filename)){
        SelectFilesArr.push(webPath+ filename);
        setSelectFileLength(SelectFilesArr.length);
        console.log(SelectFilesArr)
        document.getElementById(`Folder-${id}`).parentNode.style="background:#CAE3FF";
        document.getElementById(`Folder-${id}`).style="background:#CAE3FF";
        // Select the element by its data-bs-target attribute
        const element = document.querySelector(`[data-bs-target="#dropdownMenuButton-${id}"]`);

        // Add the desired class to the element
        element.classList.add('d-none');
            
        // document.getElementById(`check-${id}`).checked = true;
        SelectFilesArr.length !== 0 ? setSelected(true):setSelected(false);
        SelectFileIds.push(id);
        SetCheckFolderOrFile("folder")
    }
    else{
        const index=SelectFilesArr.indexOf(webPath+ filename);
        SelectFilesArr.splice(index,1);
        SelectFileIds.splice(id,1);
        setSelectFileLength(SelectFilesArr.length);
        console.log(SelectFilesArr);
        document.getElementById(`Folder-${id}`).parentNode.style="background:background:linear-gradient(180deg, #ebe9e9 0%, #dddcdc 100%)";
        document.getElementById(`Folder-${id}`).style="background:linear-gradient(180deg, #ebe9e9 0%, #dddcdc 100%)";
        const element = document.querySelector(`[data-bs-target="#dropdownMenuButton-${id}"]`);
        // Add the desired class to the element
        element.classList.remove('d-none');
        // document.getElementById(`check-${id}`).checked = false;
        SelectFilesArr.length === 0 ? setSelected(false):setSelected(true);
        SetCheckFolderOrFile("")
        
    }
    
    // SelectFileLength === 0 ? setSelected(false):setSelected(true);
}
//selectd items added start
    const selectFiles=(filename,id)=>{
        if(!SelectFilesArr.includes(webPath+ filename)){
            SelectFilesArr.push(webPath+ filename);
            setSelectFileLength(SelectFilesArr.length);
            console.log(SelectFilesArr)
            document.getElementById(`file-items-${id}`).style="background-color:#CAE3FF";
            document.getElementById(`check-${id}`).checked = true;
            SelectFilesArr.length !== 0 ? setSelected(true):setSelected(false);
            SelectFileIds.push(id);
            SetCheckFolderOrFile("file")
        }
        else{
            const index=SelectFilesArr.indexOf(webPath+ filename);
            SelectFilesArr.splice(index,1);
            SelectFileIds.splice(id,1);
            setSelectFileLength(SelectFilesArr.length);
            console.log(SelectFilesArr);
            document.getElementById(`file-items-${id}`).style="background-color:#FFF";
            document.getElementById(`check-${id}`).checked = false;
            SelectFilesArr.length === 0 ? setSelected(false):setSelected(true);
            SetCheckFolderOrFile("")
            
            
        }
        
        // SelectFileLength === 0 ? setSelected(false):setSelected(true);
    }
//selectd items added end


//push item with webpath when items share start
    const checkTimeofShare = (item) =>{
        if(SelectFilesArr.length === 0){
            SelectFilesArr.push(webPath+ item);
        }
    }
//push item with webpath when items share end

// const selectAll =() =>{
    
// }



//download items start   
    const downloadItem = async() =>{
            console.log(SelectFilesArr)
            const payload = {
                email: getEmailFromCookie,
              };
              
              if (CheckFileOrFolder === "folder") {
                payload.flist = SelectFilesArr;
              } else {
                payload.fileList = SelectFilesArr;
              }
            SetZipping(true)
            setLoading1(true)
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            };
            fetch( CheckFileOrFolder=== "folder" ? `${WebUrl}/user-folder-download/` : `${WebUrl}/user-file-download/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                SetZipping(false)
                console.log('=====>',data);
                console.log("print")
                // window.open(data);
                window.location.href = data;
                
                setLoading1(false)
                userEffectWithBase(webPath);
                DeSelectAll()
                
                // user_effect()
                // axios.get(data, {
                //     responseType: 'blob',
                //   })
                //   .then((res) => {
                //    fileDownload(res.data, "helibucket.zip");
                //    setLoading1(false)
                //    userEffectWithBase(webPath);
                //    DeSelectAll()
                //   })
                
                 
                  
            })
        
    }
//downlod items end



//download folder
const DownloadFolder = (item , key) =>{
    const downloadSingleItem = document.getElementById(!item.includes('.') ? `download_single_select-${key}` :`download_single_select-file-${key}` );
    var iconElement = downloadSingleItem.querySelector('i');
    var imgElement = downloadSingleItem.querySelector('img');
    SetZipping(true)
    // setLoading1(true)
    iconElement.classList.remove("d-block")
    iconElement.classList.add("d-none")
    imgElement.classList.remove("d-none")
    imgElement.classList.add("d-block")
    // if(SelectFilesArr.length === 0){
        SelectFilesArr.push(webPath+ item );

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: getEmailFromCookie , flist:SelectFilesArr , })
        };
        fetch(`${WebUrl}/user-folder-download/`, requestOptions)
        .then(response => response.json())
        .then(data => {
            SetZipping(false)
            console.log('=====>',data);
            window.location.href = data;
            
            setSelectFilesArr([]);
            // window.open(data, '_blank');
            iconElement.classList.add("d-block")
            iconElement.classList.remove("d-none")
            imgElement.classList.add("d-none")
            imgElement.classList.remove("d-block")
        })
        
    // }
    
}

//delete multiple files start
const deleteMultipleItem =() =>{
    console.log(webPath)
    Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
            setLoading2(true);
              const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({  email: getEmailFromCookie, fileList:SelectFilesArr})
              };
              fetch(`${WebUrl}/user-file-delete/`, requestOptions)
              .then(response => response.json())
              .then(data => {
                  console.log('=====>',data);
                  setLoading2(false);
                  Swal.fire({
                      title: 'your file delete successfully',
                      icon: 'success',
                      confirmButtonText: 'OK'
                      });
                      userEffectWithBase(webPath)
                      DeSelectAll()
              })
        //   }
        } else {
          console.log('Cancelled');
        }
      });
   
    
}



///here handle logout start
const handleLogout = () =>{
    localStorage.removeItem('email');
    localStorage.removeItem("name");
    localStorage.removeItem("storageType");
    navigate('/login')
}
///here handle logout end

const gettFolderData =(webpath) =>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: getEmailFromCookie , path: (webpath+"/") })
    };
    fetch(`${WebUrl}/trash-subfolder-list/` , requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('=====>',data);
            const totalFolderFile =[];
            for(let i=0; i<data['data'].length; i++){
                let datas = data['data'][i][0];
                totalFolderFile.push([datas]); 
            }
            return totalFolderFile


    });
}



const { getRootProps, isDragActive } = useDropzone({
    onDragOver:() =>{
        setuploaderImage(true);
    },
    onDrop: (acceptedFiles) => {
        // Handle dropped files and folders here
        console.log(acceptedFiles);
        // event.preventDefault();
        setFiles(acceptedFiles);
        show_files(acceptedFiles)
        setuploaderImage(false)
        user_effect()


      },
    onDragLeave:() =>{
        setuploaderImage(false)
    },
      multiple: true,
      directory: true,

  });


    const show_options_files_single_select = (id) => {
        document.getElementById(id).style="display:block";
    }
    const closeLinkData =()=>{
        setSelectFilesArr([]);
    }


    const hide_options_files_single_select = (id) => {
        document.getElementById(id).style="display:none";
    }


    ///delete for single  item
    const deleteItem =(item , key) =>{
        // const deleteSingleItem = document.getElementById( !item.includes('.') ? `delete_single_select-${key}` :`delete_single_select-file-${key}`);
        // const iconElement = deleteSingleItem.querySelector('i');
        // const imgElement = deleteSingleItem.querySelector('img');
        console.log(webPath)
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure ?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
            //   setLoading1(true);
            //   if(SelectFilesArr.length === 0){
                setLoading2(true);
                // iconElement.classList.remove("d-block")
                // iconElement.classList.add("d-none")
                // imgElement.classList.remove("d-none")
                // imgElement.classList.add("d-block")
                  SelectFilesArr.push(webPath+ item);
                  const requestOptions = {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({  email: getEmailFromCookie, fileList:SelectFilesArr,})
                  };
                  fetch(`${WebUrl}/user-file-delete/`, requestOptions)
                  .then(response => response.json())
                  .then(data => {
                      console.log('=====>',data);
                      setSelectFilesArr([]);
                    setLoading2(false);
                      Swal.fire({
                          title: 'your file delete successfully',
                          icon: 'success',
                          confirmButtonText: 'OK'
                          });
                          userEffectWithBase(webPath);
                  })
            //   }
            } else {
              // User clicked "No" or closed the dialog
              // Perform alternative action or do nothing
              console.log('Cancelled');
            }
          });
       
        
    }
///delete for single  item end
const onSearch =(searchvalue) =>{
    if(searchvalue.length == 0){
        Swal.fire({
            title: 'please add text',
            icon: 'error',
            confirmButtonText: 'OK'
          })
    }
    else{
        setLoading(true)

    setSearchvalue(searchvalue)
    const regex = /\//g; // Regular expression to match forward slashes
    const matches = webPath.match(regex);
    const formData = new FormData();
        formData.append('email', getEmailFromCookie);
        formData.append('username', getEmailFromCookie); // Assuming you want to set username to the email value
        formData.append('login_token', tokenCookie);
        // formData.append('path', matches && matches.length >= 2 ? webPath : undefined);
    const requestOptions = {
        method: 'POST',
        headers:matches && matches.length >= 2 ? { 'Content-Type': 'application/json' }:undefined,
        body: matches && matches.length >= 2 ? JSON.stringify({ email: getEmailFromCookie , path: matches && matches.length >= 2 ? webPath : undefined  }) : formData
    };
    fetch(matches && matches.length >= 2 ?  `${WebUrl}/trash-subfolder-list/`: `${WebUrl}/trash-file-list-with-decoretor/` , requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('=====>',data);
            setBucketName(data['bucket']);
            setAccessCode(data['access_code']);
            const filteredData = [];
            const totalFolderFile =[];
            for(let i=0; i<data['data'].length; i++){
                let datas = data['data'][i][0];
                let data_s = data['data'][i][1];
                let fileSize =data_s
                if (datas.includes(searchvalue)) {
                    if(datas.indexOf(".")===-1){
                        totalFolderFile.push(datas);
                    
                    }
                    else{
                        filteredData.push([datas, fileSize]);
                    }
                  }
                
            }
            setFolderDatas(totalFolderFile);
            setFileDatas(filteredData);
            setCommingData(true);
            setLoading(false);
            SetSearching(true)
            setLoading(false)

            console.log("webpath: ",data['bucket']+"/");
    });
    console.log(searchvalue);}
    

}
const return_back_condition =(webPath) =>{
    // setShowFile(true)
    userEffectWithBase(webPath)
    // setLoading(false)
    SetSearching(false)
    setSearchvalue("")
    
}
const filteredItems = bucketFolderFile.filter((item, key) => {
    const searchTerm = searchValue.toLowerCase();
    const filename = item[0].toLowerCase();
    return searchTerm && filename.startsWith(searchTerm) && filename !== searchTerm;
  }).slice(0, 5);



const restoreMultipleItem=() =>{
    setLoading1(true);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({  email: getEmailFromCookie, fileList:SelectFilesArr})
    };
    fetch(`${WebUrl}/remove-trash-to-normal/`, requestOptions)
    .then(response => response.json())
    .then(data => {
        console.log('=====>',data);
        setSelectFilesArr([]);
      setLoading1(false);
      user_effect()
//   }
});

}







const restoreItem =(item , key) =>{
    setLoading1(true);
    SelectFilesArr.push(BucketName+"/"+item)
    // SelectFilesArr.push(webPath+ item);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({  email: getEmailFromCookie, fileList:SelectFilesArr})
    };
    fetch(`${WebUrl}/remove-trash-to-normal/`, requestOptions)
    .then(response => response.json())
    .then(data => {
        console.log('=====>',data);
        setSelectFilesArr([]);
      setLoading1(false);
      user_effect()
//   }
});


}



  return (
    <React.Fragment>
    <div className={`loader ${loading || loading1 ? null : "d-none"} `} >
        <img className='file-icon-list' src={Loader} />
    </div>
    <div className={`loader  ${comingdata ? "d-block"  :  "d-none" } `} >
    <div className={`db-main ${loading ?  'd-none' :null} d-flex`}>
    <Sidebar sidebar={sidebar} extender={extender} BucketName ={BucketName} sidebarText={sidebarText} sizeCalculation={sizeCalculation} openSideBar={openSideBar} used_size = {useSize} BucketSize={BucketSize} uploadFileByInput ={uploadFileByInput} closeSidebar={closeSidebar}  uploaderImage ={uploaderImage} user_effect ={user_effect}   />
    <div className="right-sec">
        <div className='d-flex nav-sec flex-row-reverse flex-fill'>
            <div className="p-3 pe-5">
                <div className="btn-group">
                    <button type="button" className="btn btn-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"  >
                        <img className='profile-ico' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1679921563/User_1_wwbynm.png'/>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                        <li onClick={handleLogout}><button className="dropdown-item" type="button">log out <i class="bi bi-box-arrow-in-right"></i></button></li>
                    </ul>
                </div>
            </div>
            <div className="pt-3">
                  <p className=' fs-5 text-limit2'style={{"color":"#fff", "marginRight":"-22px","paddingTop":"15px"}}>{getNameFromCookie}</p>
            </div>
            <div className='p-3 pe-5'> 
                <input type='text' autoComplete='new-password'  onInput={search} id ="searchlist" value={searchValue} className='search-bar'/>
            {searching ? 
                (<div>
                    <button>
                        <img className="search-ico" style={{ cursor: "pointer" }} onClick={() => return_back_condition(webPath) } src={crossIcon }/>
                    </button>
                </div>) : 
                (<div className=''>
                    
                    {searchValue.length === 0 ? (
                    
                        <button type="submit"><img className="search-ico " style={{ cursor: "pointer" }}  src={SearchIco}/></button>
                    ) : (
                    <button onClick={()=>onSearch(searchValue)} ><img className="search-ico " style={{ cursor: "pointer" }}  src={SearchIco}/></button>
                    )}
                </div>)}
            <div className={`suggestionBox ${searching ?'d-none':'d-block'}`} id="suggestion_box"  >
            {bucketFolderFile.filter((item,key)=>{
                  const SerachTerm =  searchValue.toLowerCase();
                  const filename = item[0].toLowerCase();
                  return SerachTerm && filename.startsWith(SerachTerm) && filename !== SerachTerm;
            }).slice(0,5)
            .map((item,key) => (
            <div class="text-limit ps-3"onClick={()=>onSearch(extractLastParts(item[0]))} key ={key}>{extractLastParts(item[0])} </div>
            ))}
            {(searchValue.length > 0) ? filteredItems.length === 0 ?  <div class="ps-3" id ="not_found">Not found</div> : null:
            filteredItems.map((item, key) => (
                <div class="text-limit ps-3" onClick={() => onSearch(item[0])} key={key}>
                {item[0]}
                </div>
            ))}
            
           </div>
            </div>
        </div>
        
        <div {...getRootProps({ onClick: event => event.stopPropagation()})} className={`${uploaderImage ? "dropzone "  :  null} `}  >
        <div className={`pt-3 pb-3 ms-3 me-3 bg-white rounded-2 blur  ${uploaderImage ? "color-shadow "  :  null} `}>
            <div >
                <div className='d-flex '>
                <div className='ms-3 multiple-option flex ' id='multiple-option'>
                                {FolderDatas.length === 0 && FileDatas.length === 0 ? (
                            null // return null to skip rendering this item
                            ):(<div className='container'>{SelectFileLength} selected  </div> )}
                        
                        <div className={`container ${selected ? 'd-flex' : 'd-none' }`} id ="all_download" style={{'margin':'-27px 0 0 100px'}}> 
                            <button className='me-3 buttons-icon' data-bs-toggle="tooltip" data-bs-placement="top" title="delete forever" onClick={deleteMultipleItem}><i className={`bi options-icons bi-trash3 fs-6 ${loading2 ? 'd-none' :'d-block'}`}></i>
                            
                            </button>   
                            <button className='me-3 buttons-icon' data-bs-toggle="tooltip" data-bs-placement="top" title="trash to Normal" onClick={restoreMultipleItem}><i className={`bi bi-arrow-clockwise  fs-6 ${loading2 ? 'd-none' :'d-block'}`}></i>
                            
                            </button>                           
                           
                        </div>
                </div>
                    {BucketName+"/" !== webPath ? (<div className=' text-end flex-fill me-3' onClick={backSubFolder}>
                    <button type="button" class="webpath-style fs-6" >{webPath}</button><button type="button" class="btn btn-primary" > <i class="bi bi-arrow-return-left"></i></button>
                    </div>):<div className=' text-end flex-fill me-3'>
                        <span className='fs-6 me-3 '>{webPath}</span></div>}
                </div>
            </div>
            {FolderDatas.length === 0 ? (
            null // return null to skip rendering this item
            ) : (
            <div className='mt-3 d-flex'>
                <p className='fs-5 fw-bold ms-3' style={{'color':'#9C9C9C'}}>Folders </p>
            </div>
            )}
            <Carousel responsive={responsive}>
                {FolderDatas.map((item,key) => (
                <div className='ps-3' >
                    <div className="show-folder d-inline-block" key={key}>
                        <a href='#' className='text-dark'>
                            <div className="folder-card-body d-flex">
                                <div className="d-flex" id={`Folder-${key}`} onClick={() => SelectFolder(item, key)} onDoubleClick={()=>getSubFolderdata(item)}>
                                    <img className="folder-icon" src={FolderIcon} />
                                    <div className="folder-title ms-4 " >{item}</div>
                                </div>
                                <i className="bi bi-three-dots-vertical fs-5" type="button" data-bs-toggle="collapse" data-bs-target={`#dropdownMenuButton-${key}`} aria-expanded="false" aria-controls={`dropdownMenuButton-${key}`} ></i>
                            </div>
                            <div style={{'marginLeft':'200px'}} >
                                <div className="collapse"  id={`dropdownMenuButton-${key}`}>
                                    <div className=" card rounded-bottom rounded-0" style={{"width":"60px"}}>
                                        <div className='btn-group' aria-labelledby={`dropdownMenuButton-${key}`}>
                                            <a  className='me-3 ' data-bs-toggle="tooltip" data-bs-placement="top" title="delete forever" id={`delete_single_select-${key}`} onClick={()=>deleteItem(item,key)}><i className="bi options-icons bi-trash3 fs-6  fs-6 d-block"></i> 
                                            
                                            </a> 
                                            <a  className='me-3 ' data-bs-toggle="tooltip" data-bs-placement="top" title="Move to trash" id={`delete_single_select-${key}`} onClick={()=>restoreItem(item)}><i className="bi bi-arrow-clockwise   fs-6 d-block"></i> 
                                            
                                            </a>                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                ))}
            </Carousel>
            {FileDatas.length === 0 ? 
            (null ):
            (<div className='mt-3 '>
                <p className='fs-5 fw-bold ms-3' style={{'color':'#9C9C9C'}}>Files {SelectFilesArr}</p>
            </div>)
             }
        <div className='table-responsive ps-3 tbody-fixed'>
            <table className='table'>
                    {FileDatas.length === 0 ? 
                (null ):
                (<thead>
                    <tr className='bg-body-tertiary'>
                        <th scope='col'>Name</th>
                        <th scope='col'>File Size</th>
                        <th scope='col'></th>
                        {/* <th scope='col'>Modified time</th> */}
                    </tr>
                </thead>)
                }
                
                <tbody  >
                {FileDatas.map((item, key) => (
                    <tr id={`file-items-${key}`} className='p-3 file-hover' key={key} onMouseMove={() => show_options_files_single_select(`options_files_single_select-${key}`)} onMouseLeave={() => hide_options_files_single_select(`options_files_single_select-${key}`)}>
                    <td className='p-3 ' style={{'marginLeft':"20px"}}>
                        <div onClick={() => selectFiles(item[0], key)} className='fw-medium btn-group'>
                        <input className="form-check-input checkboxes" type="checkbox" value="" id={`check-${key}`} />
                        <img className='file-icon-list' style={{ cursor: 'pointer' }} src={FileIcon} />
                        &nbsp;
                        <span className='text-limit'>{extractLastParts(item[0])}</span>
                        </div>
                    </td>
                    <td className='fw-medium p-3 '>{sizeCalculation(item[1])}</td>
                    {/* <td className='fw-medium p-3 '>{item[1]}</td> */}
                    <td className='fw-medium'>
                        <div id={`options_files_single_select-${key}`} className={`c`} style={{ display: 'none' }}>
                        <button
                            className={`me-3 buttons-icon`}
                            id={`delete_single_select-file-${key}`}
                            onClick={() => deleteItem(item[0], key)}
                            data-bs-toggle="tooltip" data-bs-placement="top" title="Move to trash"
                        >
                            <i
                            className={`bi options-icons bi-trash3 fs-6 ${
                                loading1 || SelectFilesArr.length > 1 ? 'd-none' : 'd-block'
                            }`}
                            ></i>
                            <img
                            src={Loader1}
                            className={`d-none`}
                            style={{ width: '20px', height: '20px', marginTop: '2px', marginBottom: '-2px' }}
                            />
                        </button>
                        <button
                            className={`me-3 buttons-icon ${SelectFilesArr.length > 1 ? 'd-none' : null}`}
                            id={`download_single_select-file-${key}`}
                            onClick={() => restoreItem(item[0])}
                            data-bs-toggle="tooltip" data-bs-placement="top" title="view file"
                        >
                            <i className={`bi bi-arrow-clockwise fs-6 ${loading1 ? 'd-none' :'d-block'}`}></i>
                        </button>
                        </div>
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>

        </div>
        </div>
        <div className=''>
            <img src={not_found} alt="Image" className={`image-container ${ FolderDatas.length === 0 && FileDatas.length === 0 && searching === true ? "d-block" :"d-none"   } `}/>
        </div>
        <div className='' >
            <img src={DragDrop} alt="Image" className={`image-container  ${uploaderImage ? "d-block"  :  "d-none" } `}/>
        </div>

        </div>
    </div>
</div>
    </div>
    <div className={`background-not-clickable ${ShowFile ? "d-flex": "d-none"} `}>
        <div className={`new_loader ${ShowFile ? null : "d-none"} `} >
            <img className='file-icon-list ' style={{"width":"80px" , "height":"auto"}} src={loader_new} />
        </div>
    </div>
    <div className={`background-not-clickable ${loading2  ? null:'d-none'}`}>
    <div className='progress-bari p-3 rounded-2 position-absolute top-50 start-50 translate-middle'>
        <div  className='progress_div'>
            <div className='d-flex align-items-center justify-content-center postion-absolute'>
                <Lottie loop animationData={deleteIcon} play style={{ width: 200, height: 200 ,'marginButtom':'-20px' }}/>            
            </div>
            <h5 className='align-item-center text-center text-light'>deleting Please wait...</h5>
        </div>
    </div>
    </div>
    <div className={`background-not-clickable ${ProgressON  ? null:'d-none'}`}>
        <ProgressBar Progress={Progress} presentlyUploaded={presentlyUploaded} uploadFileName={uploadFileName} FileQuantityUpload = {uploadFileLength}/>
    </div>
    <ModalShare AccessCode={AccessCode} SelectedFile ={SelectFilesArr} UserEmail={getEmailFromCookie}  closeLinkData={closeLinkData} DeSelectAll = {DeSelectAll} email_list = {emailList}  />
    </React.Fragment>
  )
}
