import React  , {useState,useEffect } from 'react' ;
import {useNavigate} from "react-router-dom";
import Swal from 'sweetalert2';
import '../assets/styles/login.css'
import { Link } from "react-router-dom";
import axios from 'axios';
import { WebUrl } from '../helpers/constant';

export default function Login() {
	
	const [email , setEmail] = useState("");
	const [password , setPassword] = useState("");
	const [ip, setIP] = useState('');
	const [ipRelatedData  , setIpReletedData] = useState('');
	const navigate = useNavigate();
	const [loginLoader , SetLoginLoader]=useState(false)
	const getEmailFromCookie = localStorage.getItem('email');
	const tokenCookie = localStorage.getItem('token');
	const StorageType = localStorage.getItem('storageType');
	const redirectUrl = localStorage.getItem('redirectAfterLogin');
	const addEmailData = (e) =>{
		const emailvalue = e.target.value;
		setEmail(emailvalue);
	}

	///get ip from device
	const getIpData = async () => {
	const res = await axios.get('https://geolocation-db.com/json/')
	// console.log(res.data);
	setIpReletedData(res.data)
	setIP(res.data.IPv4)
	}

	const checkHaveBucket= () =>{
		// const randomTwoDigitNumber = () => Math.floor(Math.random() * 90) + 10;
  		// const randomNumber = randomTwoDigitNumber();
		{
			let emailFirstPart = getEmailFromCookie.split("@")[0]
            let emailFirstParts = emailFirstPart.replace(/\./g, '');
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email: getEmailFromCookie ,bucket_name :emailFirstParts })
			  };
			fetch(`${WebUrl}/create-bucket/`, requestOptions)
			.then(response => response.json())
			.then(data => {
				console.log('=====>',data);
				if (data["message"]=== "please enter different bucket name"){
					navigate('/login')
				}
				else{
					const URL=`${WebUrl}/store-login-details/`;
					const requestOptions = {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({email:email, password:password , userIp: ip , IpReletedData :ipRelatedData ,url_type :"file_folder" })
					};
					fetch(URL, requestOptions)
				.then(response => response.json())
				.then(data => {
					// console.log('=====>',data);
					const message = data['message']
					const token = data['token']
					console.log(message);
					console.log(data)
					if (message ==="Your account is not activated please activate your account from the confirmation mail"){
						Swal.fire({
							title: 'Your account not activated!',
							  text: 'Please check email!',
							icon: 'error',
							confirmButtonText: 'OK'
						  });
						  SetLoginLoader(false)
						  navigate('/login');
					}
					else if(message ==="Auth Success"){
						const formData = new FormData(); 
							formData.append('email', email);
							formData.append('username', email);
							formData.append('login_token', token);
						localStorage.setItem('name', data["user"]["username"]);
						localStorage.setItem('token', token);
						const URL=`${WebUrl}/file-details-list/`;
						const requestOptions = {
							method: 'POST',
							body: formData
						};
						fetch(URL, requestOptions)
						.then(response => response.json())
						.then(data1 => {
							if(data1["msg"]==="you are not authentic user"){
								Swal.fire({
									title: 'please login again',
									icon: 'error',
									confirmButtonText: 'OK'
								  });
								  SetLoginLoader(false)
								  navigate('/login');
		
							}
							else{
								console.log("====>",data1);
								localStorage.setItem('email', email);
								SetLoginLoader(false);
								localStorage.setItem('storageType',data1['total_size'] > 25 ? 'premimum' : null);
								navigate(data1['total_size'] > 25 ? '/dashboard-premium' : '/dashboard');
							}
							
							
							});
					 }else{
						Swal.fire({
							title: 'Invalid credential!',
							icon: 'error',
							confirmButtonText: 'OK'
						  });
						  SetLoginLoader(false)
						  navigate('/login');
					 }
			});
					StorageType=== "premimum" ?  navigate('/dashboard-premium') : navigate('/dashboard');
				}
				});
		}
	}



	
	useEffect(()=>{
		getIpData()
		if (getEmailFromCookie !== null && tokenCookie !== null ){
		const formData = new FormData();
				formData.append('email', getEmailFromCookie);
				formData.append('username', getEmailFromCookie);
				formData.append('login_token', tokenCookie);
				const URL=`${WebUrl}/file-details-list/`;
				const requestOptions = {method: 'POST',body: formData};
				fetch(URL, requestOptions)
				.then(response => response.json())
				.then(data1 => {

					if(data1['msg'] === "You have not any Bucket"){
						// checkHaveBucket()
						navigate('/login');
						}
						else{
						if(data1['msg'] === "you are not authentic user"){
							navigate('/login');
						}
						else if (redirectUrl) {
							navigate(redirectUrl);
							localStorage.removeItem('redirectAfterLogin'); // Clear the saved URL
						}
						else{
							StorageType=== "premimum"?  navigate('/dashboard-premium') : navigate('/dashboard');

						}
					}
			});
		}
		console.log(WebUrl)

	},[]);

	

const addPasswordData=(e)=>{
	setPassword(e.target.value);
}
const sendData = (e) =>{
	e.preventDefault();
	if (email ==="" && password ===""  ){
		Swal.fire({
			title: 'email or password null!',
			icon: 'error',
			confirmButtonText: 'OK'
		  });
		  navigate('/login');

	}
	else{
		SetLoginLoader(true)
	const URL=`${WebUrl}/store-login-details/`;
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({email:email, password:password , userIp: ip , IpReletedData :ipRelatedData , url_type :"file_folder" })
	};
	fetch(URL, requestOptions)
		.then(response => response.json())
		.then(data => {
			// console.log('=====>',data);
			const token = data['token']
			console.log(data)
			if (data['message'] ==="Your account is not activated please activate your account from the confirmation mail"){
				Swal.fire({
					title: 'Your account not activated!',
					  text: 'Please check email!',
					icon: 'error',
					confirmButtonText: 'OK'
				  });
				  SetLoginLoader(false)
				  navigate('/login');
			}
			else if(data['message'] ==="Auth Success"){
				const formData = new FormData();
				formData.append('email', email);
				formData.append('username', email);
				formData.append('login_token', token);
				localStorage.setItem('name', data["user"]["username"]);
				localStorage.setItem('token', token);
				const URL=`${WebUrl}/file-details-list/`;
				const requestOptions = {method: 'POST',body: formData};
				fetch(URL, requestOptions)
				.then(response => response.json())
				.then(data1 => {
					if(data1["msg"]==="you are not authentic user"){
						Swal.fire({
							title: 'please login again',
							icon: 'error',
							confirmButtonText: 'OK'
						  });
						  SetLoginLoader(false)
						  navigate('/login');

					}
					else{
						console.log("====>",data1);
						localStorage.setItem('email', email);
						if(data1['total_size'] >25){
							localStorage.setItem('storageType',"premimum");
						} 

						// console.log(data1['msg'])
						if(data1['msg'] === "You have not any Bucket"){
							SetLoginLoader(false)
                            //here have bucket cheking
							checkHaveBucket()

						}
						else {
							SetLoginLoader(false);
							if (redirectUrl) {
								navigate(redirectUrl);
								localStorage.removeItem('redirectAfterLogin'); // Clear the saved URL
							}
							else{
								localStorage.setItem('storageType',data1['total_size'] > 25 ? 'premimum' : null);
								navigate(data1['total_size'] > 25 ? '/dashboard-premium' : '/dashboard');
							}
							
													
						}
					}
					});
			 }else{
				Swal.fire({
					title: 'Invalid credential!',
					icon: 'error',
					confirmButtonText: 'OK'
				  });
				  SetLoginLoader(false)
				  navigate('/login');
			 }
	});
	}


}
  return (
	<>
  <div className="main-mobile" id="login">
  <div className="text-center">
	<form className="form" id="b-form" method="post" onSubmit={sendData} action>
	  <h2 className="form_title title mt-4">Files and Folder LogIn</h2>
	  <div className="form__icons" /><span className="form__span"> </span>
	  <input className="form__input" onChange={addEmailData}  required value={email} type="text" placeholder="Email" />
	  <input className="form__input mt-4" value={password} required onChange={addPasswordData}  type="password" placeholder="Password" />
	  <div className="d-flex">
		<input type="checkbox" className="form__link" defaultValue="lsRememberMe" id="rememberMe" /> <label htmlFor="rememberMe" className="form__link ms-2">Remember me</label>
		{/* <a className="form__link ms-5" href="#">Forgot your
		  password?</a> */}
	  </div>
	  <button className="form__button button submit">LOG IN  <span class={`spinner-border spinner-border-sm ${loginLoader ? null:'d-none' } `} role="status" aria-hidden="true"></span></button>
	  <p className="mt-3">Don't have an account?<Link to='/register' id="open-register" >Create
		  Account</Link></p>
		<p className="mt-3"><Link to='https://heliware.co.in/app/rp'  id="open-register" >Forget Password</Link></p>
	</form>
  </div>
</div>
</>




  )
}
